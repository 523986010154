import { Input, Button, Form, Spin } from "antd";
import { useQueryClient } from "react-query";
import { openNotification } from "../../../utils/notifcations";
import ComponentLoader from "../../../components/loaders/ComponentLoader";
import { textInputValidationRules } from "../../../utils/validation";
import { TEstate } from "../../estates/types";
import { useEffect } from "react";
import {
  QUERY_KEY_FOR_SINGLE_ESTATE,
  useFetchSingleEstate,
} from "../../estates/hooks/useFetchSingleEstate";
import { useUpdateEstate } from "../../estates/hooks/useUpdateEstate";
import { QUERY_KEY_FOR_ESTATES } from "../../estates/hooks/useFetchEstates";

interface IProps {
  id: string;
  handleClose: Function;
  estate?: TEstate;
}
const EditIDTemplate = ({ id, handleClose, estate }: IProps) => {
  const queryClient = useQueryClient();
  const { data: fetchedSingleEstate, isFetching } = useFetchSingleEstate({
    id,
  });

  const [form] = Form.useForm();
  const { mutate, isLoading } = useUpdateEstate();
  const handleSubmit = (data: any) => {
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      { estateId: id, props: { idCardColor: data.idCardColor } },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The ID card template was updated successfully",
            // duration: 0.4,
          });
          handleClose();
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_SINGLE_ESTATE],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_ESTATES],
          });
        },
      }
    );
  };
  useEffect(() => {
    const data = fetchedSingleEstate ?? estate;
    if (data) {
      form.setFieldsValue({
        name: data.name,
        idCardColor: data.idCardColor,
      });
    }
  }, [form, fetchedSingleEstate, id, estate]);

  if (!estate || isFetching) {
    return <ComponentLoader />;
  }
  return (
    <Form
      labelCol={{ span: 24 }}
      onFinish={handleSubmit}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        name={"name"}
        label={"Estate Name"}
        rules={textInputValidationRules}
      >
        <Input placeholder="Estate Name" disabled />
      </Form.Item>
      <Form.Item
        name={"idCardColor"}
        label={"Color"}
        rules={textInputValidationRules}
      >
        <Input placeholder="Color" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          className="w-full"
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditIDTemplate;
