import React, { useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import { usePagination } from "../../../hooks/usePagination";
import { Button, Space, Table } from "antd";
import { EyeFilled } from "@ant-design/icons";

import {
  TRequestStatus,
  useFetchServiceRequests,
} from "../hooks/useFetchServiceRequests";
import { TServiceRequest } from "../types/service-requests";
import { ViewServiceRequest } from "./ViewServiceRequest";
import moment from "moment";
interface IProps {
  estateId: string;
  status?: TRequestStatus;
}
export const ServiceRequestsTable = ({ estateId, status }: IProps) => {
  const [entityId, setEntityId] = useState<string>();
  const [show, setShow] = useState(false);
  const handleShow = (id: string) => {
    setEntityId(id);
    setShow(true);
  };
  const { pagination, onChange, resetPagination } = usePagination();
  useEffect(() => {
    resetPagination();
  }, [estateId, status, resetPagination]);

  const { data, isFetching } = useFetchServiceRequests(estateId, {
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
    status,
  });

  const columns: ColumnsType<TServiceRequest> = [
    {
      title: "Service Title",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-semibold capitalize">
            {record?.serviceTitle ?? record?.serviceId?.name}
          </span>
        </div>
      ),
    },
    {
      title: "Resident Name",
      dataIndex: "ResName",
      key: "ResName",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span>
            {record?.residentData?.estate[0]?.firstName}{" "}
            {record?.residentData?.estate[0]?.lastName}
          </span>
        </div>
      ),
    },
    {
      title: "Service Price",
      dataIndex: "serv",
      key: "serv",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-semibold capitalize">
            {record?.serviceCost ?? record.serviceId.price}
          </span>
        </div>
      ),
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-semibold capitalize">
            {record.transactionStatus}
          </span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-semibold capitalize">
            {" "}
            {moment(record.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <Button
            title="View Request"
            icon={<EyeFilled />}
            type="text"
            // onClick={() => handleShow(item._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {entityId && (
        <ViewServiceRequest
          id={entityId}
          handleClose={() => {
            setShow(false);
            setEntityId(undefined);
          }}
          open={show}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
