import { Avatar, Badge, Input, Layout, Space, Typography } from "antd";
import { SearchOutlined, MessageOutlined } from "@ant-design/icons";
import { NotificationIcon } from "../../icons";

const { Header } = Layout;

const DashboardHeader = () => {
  return (
    <Header
      className="header-content-container bg-white"
      style={{ position: "sticky", top: 0, zIndex: 7 }}
    >
      <div className="top-header flex justify-between">
        <div className="w-60 relative">
          <Input placeholder="Search" />
          <div className="absolute top-6 right-0 px-2 flex pb-1 rounded ">
            <SearchOutlined size={100} />
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <Space
            align="center"
            size={"large"}
            className="chat-box bg-slate-200"
            style={{ cursor: "pointer" }}
          >
            <div className="flex items-center gap-2">
              <MessageOutlined />
              <Typography.Text>Chat</Typography.Text>
            </div>
            <Badge
              text={`0`}
              status="success"
              size="small"
              // count={22}
              dot={false}
              // className="round bg-grey p-5 red"
            />
          </Space>
          <NotificationIcon />
          <Avatar src="" />
        </div>
      </div>
    </Header>
  );
};

export default DashboardHeader;
