import { Button, Skeleton } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { PageCreator } from "./PageCreator";
import { PagePreviewer } from "./PagePreviewer";
import { TCreatePageContent } from "../types";

type TContentToShow = "create" | "preview";

const MIN_LETTER_COUNT_OF_TITLE = 3;
interface IProps {
  onlyFAQ?: boolean;
  isLoading?: boolean;
  defaultContentToShow?: TContentToShow;
  defaultPageContent?: TCreatePageContent;
  showSavePage?: boolean;
  submit: { fn: (val: TCreatePageContent) => void; loading?: boolean };
}
export const CreateHelpPageContainer: React.FC<IProps> = ({
  onlyFAQ = false,
  defaultContentToShow = "create",
  defaultPageContent = {
    title: "",
    content: "",
  },
  showSavePage = true,
  submit,
  isLoading,
}) => {
  const [contentToShow, setContentToShow] =
    useState<TContentToShow>(defaultContentToShow);
  const [pageContent, setPageContent] =
    useState<TCreatePageContent>(defaultPageContent);
  useEffect(() => {
    if (onlyFAQ) {
      setPageContent((val) => ({ ...val, category: "faq" }));
    }
  }, [onlyFAQ]);

  return (
    <div className="flex flex-col gap-2">
      {/* controls */}
      <div className="flex justify-end gap-4">
        {" "}
        {contentToShow === "preview" && (
          <Button
            type="text"
            icon={<EyeInvisibleFilled />}
            title="create page"
            onClick={() => setContentToShow("create")}
            disabled={
              pageContent?.title.trim().length <= MIN_LETTER_COUNT_OF_TITLE
            }
          />
        )}
        {contentToShow === "create" && (
          <Button
            icon={<EyeFilled />}
            title="preview page"
            type="text"
            onClick={() => setContentToShow("preview")}
            disabled={
              pageContent?.title.trim().length <= MIN_LETTER_COUNT_OF_TITLE
            }
          />
        )}
        {showSavePage && contentToShow === "create" && (
          <Button
            type="primary"
            onClick={() => submit.fn(pageContent)}
            loading={submit.loading}
            disabled={
              pageContent?.title.trim().length <= MIN_LETTER_COUNT_OF_TITLE
            }
          >
            Save Page
          </Button>
        )}
      </div>
      <Skeleton loading={isLoading} active paragraph={{ rows: 12 }}>
        <>
          {" "}
          {/* content - creator, preview */}
          {contentToShow === "create" && (
            <PageCreator
              handleContent={(val) => setPageContent(val)}
              data={pageContent}
              onlyFAQ={onlyFAQ}
            />
          )}
          {contentToShow === "preview" && <PagePreviewer data={pageContent} />}
        </>
      </Skeleton>
    </div>
  );
};
