import { Spin } from "antd";

const ComponentLoader = ({ height = "h-72" }: { height?: string }) => {
  return (
    <div className={`${height} pt-6  w-full flex justify-center items-center`}>
      <Spin size="large" tip="Just a second ..." />
    </div>
  );
};

export default ComponentLoader;
