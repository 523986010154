import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

interface IUpdateStaffProps {
  staffId: string;
  estateId: string;
}
export const deleteStaff = (props: IUpdateStaffProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/staff/${props.staffId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId: props.estateId,
    },
  };

  const res: any = axios.delete(url, config);
  return res;
};

export const useDeleteStaff = () => {
  return useMutation(deleteStaff);
};
