export const pluralOrSingular = ({
  amount,
  singular,
  plural,
}: {
  amount?: number;
  singular: string;
  plural: string;
}) => {
  if (!amount || amount === 0) return `No ${plural}`;
  return `${amount} ${amount === 1 ? singular : plural}`;
};
