import { Button, Modal, Space, Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { usePagination } from "../../../hooks/usePagination";
import { QUERY_KEY_FOR_STAFF, useFetchStaff } from "../hooks/useFetchStaff";
import { TStaff, TStaffRole } from "../types";
import { useEffect, useState } from "react";
import EditStaff from "./EditStaff";
import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";
import { useDeleteStaff } from "../hooks/useDeleteStaff";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

interface IProps {
  estateId: string;
  role?: TStaffRole;
}

const StaffTable = ({ estateId, role }: IProps) => {
  const queryClient = useQueryClient();

  const [staffId, setStaffId] = useState<string>();
  const [edit, setEdit] = useState(false);
  const { pagination, onChange, resetPagination } = usePagination();
  useEffect(() => {
    //so as to enable pagination to be reset evry time estate id changes
    resetPagination();
  }, [estateId, resetPagination]);

  const { data, isFetching } = useFetchStaff(estateId, {
    role,
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  const handleEdit = (id: string) => {
    setStaffId(id);
    setEdit(true);
  };
  const { mutate, isLoading } = useDeleteStaff();

  const handleDeleteStaff = ({ id }: { id: string }) => {
    setStaffId(id);

    Modal.confirm({
      title: `Do you want to delete staff ?`,
      icon: <ExclamationCircleOutlined />,
      content: "This will permenantly delete this staff!",
      width: 600,
      onOk() {
        mutate(
          {
            staffId: id,
            estateId,
          },
          {
            onError: (err: any) => {
              openNotification({
                state: "error",
                title: "Error Occured",
                description:
                  err?.response.data.message ??
                  err?.response.data.error.message,
              });
            },
            onSuccess: (res: any) => {
              openNotification({
                state: "success",

                title: "Success",
                description: "The staff was deleted successfully!",
                // duration: 0.4,
              });
              setStaffId(undefined);

              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_FOR_STAFF],
              });
            },
          }
        );
      },
    });
  };

  const columns: ColumnsType<TStaff> = [
    {
      title: "Name & Email",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            {record.estate[0].firstName} {record.estate[0].lastName}
          </span>
          <span> {record.estate[0].email}</span>
        </div>
      ),
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (value, record) => `${record.estate[0].phoneNumber}`,
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (value, record) => `${record.estate[0].homeAddress}`,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value, record) => `${record.estate[0].role}`,
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <PermissionRestrictor requiredPermissions={["edit-staff"]}>
            <Button
              title="Edit Staff"
              icon={<EditOutlined />}
              type="text"
              onClick={() => handleEdit(item.estate[0]._id)}
            />
          </PermissionRestrictor>
          {/* TO DO: Make an archive not delete staff */}
          {/* <PermissionRestrictor requiredPermissions={["archive-staff"]}>
            <Button
              title="Delete staff"
              icon={<DeleteFilled />}
              loading={staffId === item.estate[0]._id && isLoading}
              type="text"
              onClick={() => handleDeleteStaff({ id: item.estate[0]._id })}
            />
          </PermissionRestrictor> */}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {staffId && (
        <EditStaff
          id={staffId}
          handleClose={() => setEdit(false)}
          open={edit}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};

export default StaffTable;
