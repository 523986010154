import React from "react";
import { TPermission } from "../../modules/adminstration/types/roles";
import { useAuthData } from "../../hooks/useAuthData";
import { cpaatRoles } from "../../modules/adminstration/constants";

export const canUserAccessComponent = ({
  userRoleName,
  requiredPermissions,
}: {
  userRoleName: string;
  requiredPermissions: TPermission[];
}) => {
  let canAccess = false;
  const userRole = cpaatRoles.find((item) => item.name === userRoleName);
  if (userRole) {
    if (
      userRole.permissions
        .filter((item) => requiredPermissions.includes(item.permission))
        .some((item) => item.active)
    )
      canAccess = true;
  }
  return canAccess;
};

export const PermissionRestrictor: React.FC<{
  children: React.ReactNode;
  requiredPermissions: TPermission[];
}> = ({ requiredPermissions, children }) => {
  const { user } = useAuthData();

  return (
    <>
      {canUserAccessComponent({
        userRoleName: user.cpaatAdminRole,
        requiredPermissions,
      }) ? (
        <>{children}</>
      ) : null}
    </>
  );
};
