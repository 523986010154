import { Typography } from "antd";
import React from "react";
import { FAQContainer } from "../components/FAQContainer";

const FAQPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <Typography.Title level={3}>Frequently Asked Questions</Typography.Title>
      <FAQContainer />
    </div>
  );
};

export default FAQPage;
