import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

interface IUpdateEstateProps {
  description?: string;
  location?: string;
  addGatePassApproval?: boolean;
  subAccountCode?: string;
  idCardColor?: string;
}
export const updateEstate = (vals: {
  estateId: string;
  props: IUpdateEstateProps;
}) => {
  const { estateId, props } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/estate/${estateId}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.patch(url, data, config);
  return res;
};

export const useUpdateEstate = () => {
  return useMutation(updateEstate);
};
