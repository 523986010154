import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

// TO DO : Ensure this is the correct schema to be used
interface IUpdateBuildingProps {
  cpaatAdminGroups: string[];
  cpaatAdminRole: string;
}
export const updateData = (vals: {
  email: string;
  props: IUpdateBuildingProps;
}) => {
  const { props, email } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/cpaat-data/staff/info/${email}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.patch(url, data, config);
  return res;
};

export const useAddGroupsToCpaatStaff = () => {
  return useMutation(updateData);
};
