import { Input, Select } from "antd";
import React, { useState } from "react";
import {
  generalValidationRules,
  textInputValidationRules,
} from "../utils/validation";
import { dialCodes } from "../constants/dialCodes";

export const FormPhoneInput: React.FC<{ Form: any }> = ({ Form }) => {
  const [searchedDialCodes, setSarchedDialCodes] = useState(dialCodes);

  return (
    <Form.Item name="phone" label="Phone Number">
      <Input.Group compact>
        <Form.Item
          noStyle
          rules={generalValidationRules}
          name={["phone", "code"]}
          initialValue={"+234"}
        >
          {
            <Select
              placeholder="Country Code"
              onSearch={(value) =>
                setSarchedDialCodes(() =>
                  dialCodes.filter(
                    (item) => item.label.toLowerCase().indexOf(value) !== -1
                  )
                )
              }
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              options={searchedDialCodes}
              allowClear
              className="rounded border-slate-400"
              style={{ width: "25%" }}
            />
          }
        </Form.Item>
        <Form.Item
          noStyle
          rules={textInputValidationRules}
          name={["phone", "number"]}
        >
          <Input
            style={{ width: "75%" }}
            placeholder="Number"
            className="rounded border-slate-400 text-left"
            autoComplete="phone"
          />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};
