import { Typography } from "antd";
import React from "react";
import { HelpPagesContainer } from "../components/HelpPagesContainer";

const Support = () => {
  return (
    <div className="flex flex-col gap-4">
      <Typography.Title level={3}>Support</Typography.Title>
      <HelpPagesContainer />
    </div>
  );
};

export default Support;
