import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { IEstateOwner } from "../types";

export const QUERY_KEY_FOR_ESTATE_OWNERS = "estate-owners";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getEstateOwners = async (
  props: IGetListDataProps
): Promise<{ data: IEstateOwner[]; total: number }> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/estate-owner`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: IEstateOwner[] = result.map(
    (item: IEstateOwner): IEstateOwner => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchEstateOwners = (props: IGetListDataProps = {}) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_ESTATE_OWNERS, props.pagination, props.searchParams],
    () =>
      getEstateOwners({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
