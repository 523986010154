import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { textInputValidationRules } from "../../../utils/validation";

import { openNotification } from "../../../utils/notifcations";
import { useCreateGroup } from "../hooks/useCreateGroup";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_CPAAT_GROUPS } from "../hooks/useGetCpaatGroups";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export const CreateGroup: React.FC<IProps> = ({ open, handleClose }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateGroup();
  const handleSubmit = (data: any) => {
    mutate(
      {
        props: {
          groupName: data.groupName,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "Group created successfully",
            // duration: 0.4,
          });
          handleClose();
          queryClient.invalidateQueries([QUERY_KEY_FOR_CPAAT_GROUPS]);
          form.resetFields();
        },
      }
    );
  };
  return (
    <Modal
      style={{ top: 10 }}
      title="Add Staff to Groups"
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="groupName"
          label="Group Name"
          rules={textInputValidationRules}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
