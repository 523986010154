import React from "react";
import { TCpaatGroupParsed } from "../types";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePagination } from "../../../hooks/usePagination";
import { useGetCpaatGroups } from "../hooks/useGetCpaatGroups";

export const CpaatGroupsTable = () => {
  const { pagination, onChange } = usePagination();
  const { data, isFetching } = useGetCpaatGroups({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });
  const columns: ColumnsType<TCpaatGroupParsed> = [
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record.name}</span>
        </div>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <Table
        dataSource={data?.data ?? []}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.data?.length }}
        onChange={onChange}
      />
    </div>
  );
};
