import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";

export const SingleEstateConfigurationCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  return (
    <PrimaryCard
      {...{
        amount: 30,
        title: "Configuration Settings",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "Active",
            value: `${pluralOrSingular({
              amount: 20,
              singular: "feature",
              plural: "features",
            })}`,
            bgColor: "#E7FEFF",
            textColor: "#0CCD09",
          },
          {
            name: "Disabled",
            value: `${pluralOrSingular({
              amount: 20,
              singular: "feature",
              plural: "features",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
