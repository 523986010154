import React from "react";
import { CARD_CLASS_NAME } from "../../../constants/commonClassNames";
import { BarChart } from "../../../components/charts/BarChart";
import { PLAN_BG_COLORS, PLAN_LABELS } from "../../../constants/chartData";

export const EstatesCategorizedByPlan = () => {
  return (
    <div className={`${CARD_CLASS_NAME} flex flex-col gap-4`}>
      <div className="border-b pb-2 ">
        <h4> Number of estate in each plan </h4>
      </div>

      <div>
        <BarChart
          labels={PLAN_LABELS}
          data={[6000, 2893, 1290]}
          axis="y"
          dataEntityLabel="Estates"
          bgColors={PLAN_BG_COLORS}
        />
      </div>
    </div>
  );
};
