import { PageHeader } from "../../../components/page/PageHeader";

import React from "react";

const ContactSupportPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <PageHeader title="Contact Support" />
    </div>
  );
};

export default ContactSupportPage;
