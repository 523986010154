import {
  Input,
  Button,
  Form,
  Spin,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  message,
  Upload,
  Typography,
  Avatar,
} from "antd";
import moment from "moment";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import {
  getEstate,
  updateEstate,
  IEditEstateProps,
} from "../../../helpers/estate";
import { getEstateOwners } from "../../../helpers/estateOwners";
import { getPaystackBanks } from "../../../helpers/paystack";
import { openNotification } from "../../../utils/notifcations";
import {
  generalValidationRules,
  textInputValidationRules,
} from "../../../utils/validation";
import { COUNTRIES } from "../../../constants/countries";
import { CURRENCY_OPTIONS } from "../../../constants/currencies";

type TBank = {
  name: string;
  code: string;
};

interface IProps {
  id: string;
  handleClose: Function;
}
const EditEstate = ({ id, handleClose }: IProps) => {
  const [fileList, setFilelist] = useState<any>([]);

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };
  const queryClient = useQueryClient();
  const { data: estate, isSuccess: isEstateSuccess } = useQuery(
    ["estate", id],
    () => getEstate({ estateId: id }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      onSuccess: (data) => {
        console.log("DATA", data);
        const result = {
          estateOwnerId: data.estateOwnerId["_id"],
          name: data.name,
          logo: data.logo,
          location: data.location,
          description: data.description,
          addGatePassApproval: data.addGatePassApproval,
          nextRenewalDate: moment(data.nextRenewalDate),
        };
        form.setFieldsValue({ ...result });
      },
      select: (res: any) => {
        const result = res.data.data;

        return result;
      },
    }
  );
  const [form] = Form.useForm();
  const { mutate } = useMutation(updateEstate);
  const handleSubmit = async (props: any) => {
    const data: IEditEstateProps = {
      ...props,
      estateId: id,
      nextRenewalDate: props.nextRenewalDate?.format("YYYY-MM-DD"),
      logo: fileList[0]?.originFileObj,
    };
    console.log("result", data);
    // return;
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      { ...data },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          const result = res.data.data;

          openNotification({
            state: "success",

            title: "Success",
            description: "The estate was updated successfully",
            // duration: 0.4,
          });
          handleClose();
          form.resetFields();
          setFilelist([]);
          queryClient.invalidateQueries({
            queryKey: ["estates"],
          });
        },
      }
    );
  };
  const {
    data: owners,

    isSuccess: isOwnersSuccess,
  } = useQuery(
    "estate-owners",
    () => getEstateOwners({ limit: "10", offset: "0" }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      select: (res) => {
        const result = res.data.data;
        // openNotification({
        //   state: "success",

        //   title: "Success",
        //   description: "Estate Owners Fetched !",
        //   // duration: 0.4,
        // });
        return result.result;
      },
    }
  );
  const {
    data: banks,

    isSuccess: isBankSuccess,
  } = useQuery("paystack-banks", () => getPaystackBanks(), {
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onError: (err: any) => {
      // show notification
      openNotification({
        state: "error",
        title: "Error Occured",
        description:
          err?.response.data.message ?? err?.response.data.error.message,
      });
    },
    select: (res) => {
      const result = res.data.data;
      console.log("DATA", result);
      const data: TBank[] = result.map(
        (item: any): TBank => ({ name: item.name, code: item.code })
      );
      return data;
    },
  });
  return (
    <div>
      {isBankSuccess && isEstateSuccess && isOwnersSuccess ? (
        <Form
          labelCol={{ span: 24 }}
          onFinish={handleSubmit}
          form={form}
          requiredMark={false}
        >
          <div className="mb-4">
            {fileList.length === 0 && (
              <>
                <div className="flex justify-center">
                  <Avatar src={estate.logo} size="large" />
                </div>
                <br />
              </>
            )}
            <Typography.Text>
              {fileList.length !== 1
                ? "New Logo:"
                : "New Logo has been inserted"}
            </Typography.Text>
            <br />

            <Upload
              listType="picture"
              fileList={fileList}
              defaultFileList={[
                {
                  uid: "1",
                  name: "logo.png",
                  status: "done",
                  url: estate.logo,
                },
              ]}
              className="avatar-uploader"
              onChange={handleUpload}
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFilelist(newFileList);
              }}
              beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
            >
              {fileList.length !== 1 && (
                <Button type="dashed">Upload Estate Logo</Button>
              )}
            </Upload>
          </div>
          <Form.Item name={"estateOwnerId"} label={`Estate Owner`}>
            <Select
              placeholder="Estate Owner"
              options={owners.map((item: any) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item["_id"],
              }))}
            />
          </Form.Item>
          <Form.Item
            name={"name"}
            label={`Estate Name`}
            rules={textInputValidationRules}
          >
            <Input placeholder="name" />
          </Form.Item>
          <Form.Item
            name={"country"}
            label={`Country of Origin`}
            rules={generalValidationRules}
          >
            <Select
              placeholder="Country of Origin"
              options={COUNTRIES.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name={"currency"}
            label={`Default Currency`}
            rules={generalValidationRules}
          >
            <Select placeholder="Default Currency" options={CURRENCY_OPTIONS} />
          </Form.Item>
          {/* <Form.Item name={"accountNumber"}>
            <Input placeholder="account Number" />
          </Form.Item>

          <Form.Item name={"bankCode"}>
            <Select
              placeholder="bank Code"
              options={banks.map((item) => ({
                label: item.name,
                value: item.code,
              }))}
            />
          </Form.Item>

          <Form.Item name={"dependantFee"}>
            <InputNumber
              placeholder="dependant Fee"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name={"primaryResidentFee"}>
            <InputNumber
              placeholder="primary Resident Fee"
              style={{ width: "100%" }}
            />
          </Form.Item> */}

          <Form.Item name={"nextRenewalDate"} label={`Next Renewal Date`}>
            <DatePicker
              placeholder="next Renewal Date"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name={"location"} label={`Location`}>
            <Input.TextArea placeholder="location" />
          </Form.Item>

          <Form.Item name={"description"} label={`Description`}>
            <Input.TextArea placeholder="description" />
          </Form.Item>
          <Form.Item
            name={"addGatePassApproval"}
            label="Do you want to add GatePassApproval?"
            valuePropName="checked"
            initialValue
          >
            <Switch
              unCheckedChildren="No"
              checkedChildren="yes"
              checked={estate.addGatePassApproval}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="w-full" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        "loading ..."
      )}
    </div>
  );
};

export default EditEstate;
