import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { useFetchGHUsersData } from "../../users/hooks/useFetchGHUsersData";

export const TotalNoOfUsersCard = () => {
  const { data } = useFetchGHUsersData({ infoType: "total-number" });
  return (
    <PrimaryCard
      {...{
        amount: data?.totalUsers,
        title: "Total Number of users",
        imgUrl: "/assets/images/blue_people.png",
        items: [],
      }}
    />
  );
};
