import { useState, useCallback } from "react";
import { TablePaginationConfig } from "antd/es/table";
import { TableProps } from "antd";

const DEFAULT_PAGE_SIZE = 20;

export const usePagination = ({
  pageSize = DEFAULT_PAGE_SIZE,
}: { pageSize?: number } = {}) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize,
    total: 0,
    showSizeChanger: false,
  });
  const resetPagination = useCallback(() => {
    setPagination(() => ({
      current: 1,
      pageSize,
      total: 0,
      showSizeChanger: false,
    }));
  }, [pageSize]);

  const offset =
    pagination.current && pagination.current !== 1
      ? (pagination.pageSize ?? 4) * (pagination.current - 1)
      : 0;
  const onChange: TableProps<any>["onChange"] = (
    newPagination: TablePaginationConfig
  ) => {
    setPagination((pagination) => ({
      ...pagination,
      ...newPagination,
    }));
  };
  return {
    onChange,
    pagination: {
      ...pagination,
      limit: pagination.pageSize,
      offset,
    },
    resetPagination,
  };
};
