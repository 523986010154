import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { THelpPage } from "../types";

export const QUERY_KEY_FOR_HELP_PAGES = "help-pages";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getStaff = async (
  props: IGetListDataProps & { category?: string }
): Promise<{ data: THelpPage[]; total: number }> => {
  const { pagination, searchParams } = props;
  let url = `${process.env.REACT_APP_BASE_URL}/help-page/`;
  if (!!props?.category) {
    url += `content/category/${props.category}`;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: THelpPage[] = result.map(
    (item: THelpPage): THelpPage => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useGetHelpPages = (
  props: IGetListDataProps & { category?: string } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_HELP_PAGES,
      props.pagination,
      props.searchParams,
      props.category,
    ],
    () =>
      getStaff({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
