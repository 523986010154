import React, { useRef } from "react";
import "../styles/idTemplate.css";
import { Avatar, Button, Divider } from "antd";
import { useReactToPrint } from "react-to-print";
import { TEstate } from "../../estates/types";
import { useFetchSingleEstate } from "../../estates/hooks/useFetchSingleEstate";
import ComponentLoader from "../../../components/loaders/ComponentLoader";
import QRCode from "react-qr-code";

interface IProps {
  id: string;
  handleClose: Function;
  estate?: TEstate;
}

const DEFAULT_USER_IMAGE = "/assets/user.jpg";
export const IDTemplatePreview = ({
  id,
  handleClose,
  estate: staleEstate,
}: IProps) => {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { data: fetchedSingleEstate, isFetching } = useFetchSingleEstate({
    id,
  });
  const estate = staleEstate ?? fetchedSingleEstate;

  if (!staleEstate || isFetching) {
    return <ComponentLoader />;
  }
  return (
    <div className="flex flex-col items-center justify-center">
      {/* <Button onClick={() => handlePrint()}>Print ID Card</Button> */}
      <div
        className="template-container shadow-lg relative rounded-lg"
        ref={componentRef}
      >
        {/* background */}
        <div className="absolute flex flex-col items-center">
          <div
            className="square"
            style={{ background: estate?.idCardColor ?? "#25aae1" }}
          ></div>
          <div
            className="arrow-down"
            style={{ borderTopColor: estate?.idCardColor ?? "#25aae1" }}
          ></div>
        </div>
        {/* content */}
        <div className="flex flex-col gap-4 py-4 relative z-10">
          {/* company */}
          <div className="flex flex-col gap-1 items-center mb-4">
            <Avatar
              shape="circle"
              className="border border-white"
              src={estate?.logo}
              size={"large"}
            />
            <div
              className="block mt-0 text-xs tracking-widest uppercase text-white"
              // style={{ fontSize: "0.65rem" }}
            >
              {estate?.name}
            </div>
          </div>
          {/* user */}
          <div className="flex flex-col gap-2 items-center">
            <Avatar
              shape="square"
              className="border border-white bg-slate-600"
              src={DEFAULT_USER_IMAGE}
              size={100}
            />
            <div className="text-center uppercase">
              <div
                className="text-lg font-semibold block mb-0"
                style={{ color: estate?.idCardColor }}
              >
                James Destro
              </div>
              <div className="block mt-0 text-xs tracking-widest text-slate-400">
                Resident
              </div>
            </div>
            <div className="flex flex-col gap-1 mt-4 w-full px-8">
              <NameValueComp
                {...{
                  name: "ID no.",
                  value: "63fdfcf43000eaa8691e69l29",
                  color: estate?.idCardColor,
                }}
              />
              {/*
              <NameValueComp
                {...{
                  name: "DOB",
                  value: "02/07/2019",
                  color: estate?.idCardColor,
                }}
              />
               <NameValueComp
                {...{
                  name: "Blood Group",
                  value: "A",
                  color: estate?.idCardColor,
                }}
              />
              <NameValueComp
                {...{
                  name: "Email",
                  value: "james.destro@example.com",
                  color: estate?.idCardColor,
                }}
              />
              <NameValueComp
                {...{
                  name: "Phone",
                  value: "081448900900",
                  color: estate?.idCardColor,
                }}
              />
              <NameValueComp
                {...{
                  name: "Expiry Date",
                  value: "02/07/2023",
                  color: estate?.idCardColor,
                }}
              /> */}
            </div>
            <div className=" mb-6 mt-4">
              <QRCode value={"GATEHOUE"} size={200} />
            </div>
          </div>
          {/* line */}

          <div className="w-full flex justify-center border-b-2 border-[#00A8ED] pt-2 pb-2 px-2">
            <div className="text-xs text-center block italic text-[#00A8ED]">
              {estate?.location}
            </div>
          </div>
          <div className="w-full flex justify-center pb-2 px-2">
            <div className="text-xs text-center block text-slate-400">
              If found please return to the owner. Thank You
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NameValueComp: React.FC<{
  name: string;
  value: string;
  color?: string;
}> = ({ name, value, color }) => {
  return (
    // original
    // <div className="flex text-xs font-semibold">
    //   <div className="w-2/5 mr-2  text-slate-700">{name}:</div>
    //   <div className="flex-1 flex justify-end text-slate-800">{value}</div>
    // </div>

    // Product Owner Request
    <div className="flex text-xs font-semibold">
      {/* <div className="w-2/5 mr-2  text-slate-700">{name}:</div> */}
      <div className="flex-1 flex justify-center text-slate-800">{value}</div>
    </div>
  );
};
