import axios from "axios";

import { useQuery } from "react-query";
import { TGHTotalUsersCountData, TGHUsersDataInfoType } from "../types";

export const QUERY_KEY_FOR_GH_USERS_DATA = "gh-users-data";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getGHUsersData = async (props: {
  infoType: TGHUsersDataInfoType;
}): Promise<TGHTotalUsersCountData> => {
  const url = `${process.env.REACT_APP_BASE_URL}/users/${props.infoType}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  return fetchedData;
};

export const useFetchGHUsersData = (props: {
  infoType: TGHUsersDataInfoType;
}) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_GH_USERS_DATA, props.infoType],
    () =>
      getGHUsersData({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
