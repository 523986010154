import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TCpaatGroup, TCpaatGroupParsed } from "../types";

export const QUERY_KEY_FOR_CPAAT_GROUPS = "cpaat-groups";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getData = async (
  props: IGetListDataProps
): Promise<{ data: TCpaatGroupParsed[] }> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/cpaat-data/groups`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const result = res.data.data;

  const data: TCpaatGroup[] = result.map(
    (item: TCpaatGroup): TCpaatGroup => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data: data[0]?.staffGroups.map((item) => ({ name: item })),
  };

  return ans;
};

export const useGetCpaatGroups = (props: IGetListDataProps = {}) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_CPAAT_GROUPS, props.pagination, props.searchParams],
    () =>
      getData({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
