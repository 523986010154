import React, { useState } from "react";
import { CpaatStaffTable } from "./CpaatStaffTable";
import { Button } from "antd";
import { AddCpaatStaff } from "./AddCpaatStaff";

export const CpaatStaffContainer = () => {
  const [showD, setShowD] = useState(false);
  return (
    <>
      <AddCpaatStaff open={showD} handleClose={() => setShowD(false)} />
      <div className="flex flex-col gap-4">
        <div className="flex justify-end">
          <Button type="primary" onClick={() => setShowD(true)}>
            {" "}
            Add Cpaat Staff
          </Button>
        </div>
        <CpaatStaffTable />
      </div>
    </>
  );
};
