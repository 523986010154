import { Tabs, Typography } from "antd";

import { useState } from "react";
// import { TransactionsContainer } from "../components/TransactionsContainer";
import { ServiceCategoryContainer } from "../components/ServiceCategoryContainer";
import { ServicesContainer } from "../components/ServicesContainer";
import { ServiceRequestsContainer } from "../components/ServiceRequestsContainer";
import { canUserAccessComponent } from "../../../components/permission-restrictor/PermissionRestrictor";
import { useAuthData } from "../../../hooks/useAuthData";

export const Services = () => {
  const [key, setKey] = useState<string>("Services");
  const handleKey = (val: string) => {
    setKey(val);
  };
  const { user } = useAuthData();

  let tabItems = [
    // {
    //   key: "Transactions",
    //   label: "Transactions",
    //   children: <TransactionsContainer />,
    // },
    {
      key: "Service Category",
      label: "Service Category",
      children: <ServiceCategoryContainer />,
    },
    {
      key: "Services",
      label: "Services",
      children: <ServicesContainer />,
    },

    {
      key: "Service Requests",
      label: "Service Requests",
      children: <ServiceRequestsContainer />,
    },
  ];
  if (
    canUserAccessComponent({
      userRoleName: user.cpaatAdminRole,
      requiredPermissions: ["view-service-request"],
    }) === false
  ) {
    tabItems = tabItems.filter((item) => item.key !== "Service Requests");
  }
  if (
    canUserAccessComponent({
      userRoleName: user.cpaatAdminRole,
      requiredPermissions: ["view-service"],
    }) === false
  ) {
    tabItems = tabItems.filter((item) => item.key !== "Services");
  }
  if (
    canUserAccessComponent({
      userRoleName: user.cpaatAdminRole,
      requiredPermissions: ["view-service-category"],
    }) === false
  ) {
    tabItems = tabItems.filter((item) => item.key !== "Service Category");
  }
  return (
    <div>
      <Typography.Title level={3}>Services</Typography.Title>

      <Tabs onChange={(val) => setKey(val)} activeKey={key} items={tabItems} />
    </div>
  );
};
