import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

// TO DO : Ensure this is the correct schema to be used
interface ICreateDataProps {
  name: string;
  email: string;
  password: string;
  //   in future , if backend makes this possible when creating a new cpaat staff
  cpaatAdminGroups?: string[];
  cpaatAdminRole?: string;
}
export const createData = (vals: { props: ICreateDataProps }) => {
  const { props } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/auth/cpaat-admin/register`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.post(url, data, config);
  return res;
};

export const useAddCpaatStaff = () => {
  return useMutation(createData);
};
