import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

export enum EOwnershipType {
  rent = "rent",
  bought = "bought",
}
interface ICreateResProps {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender: string;
  phoneNumber: string;
  building: string;
  unit?: string;
  yearOfEntry: string;
  nextRenewalDate: string;
  prefix: string;
  ownershipType: EOwnershipType;
  estateId: string;
  photo?: any;
  isResident: boolean;
}
export const createPrimaryRes = (props: ICreateResProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/primary-resident/`;

  const data = {
    email: props.email,
    estate: [
      {
        estateId: props.estateId,
        prefix: props.prefix,
        firstName: props.firstName,
        lastName: props.lastName,
        middleName: props.middleName,
        phoneNumber: props.phoneNumber,
        gender: props.gender,
        building: [
          {
            buildingId: props.building,
            unitNumber: props.unit,

            ownershipType: props.ownershipType,
          },
        ],
        yearOfEntry: props.yearOfEntry,
        nextRenewalDate: props.nextRenewalDate,
        isResident: !!props.isResident,
      },
    ],
  };
  let formData = new FormData();
  props.photo?.originFileObj &&
    formData.append("photo", props.photo?.originFileObj);
  formData.append("email", data.email);
  formData.append("estate[0]", JSON.stringify(data.estate[0]));
  formData.append(
    "estate[0].building",
    JSON.stringify(data.estate[0].building[0])
  );
  formData.append(
    "estate[0].building[0]",
    JSON.stringify(data.estate[0].building[0])
  );
  // formData.append("estate", JSON.stringify(data.estate));
  formData.append(
    "estate[0].building[0].ownershipType",
    data.estate[0].building[0].ownershipType
  );
  formData.append(
    "estate[0].building[0].buildingId",
    data.estate[0].building[0].buildingId
  );
  data.estate[0].building[0].unitNumber &&
    formData.append(
      "estate[0].building[0].unitNumber",
      data.estate[0].building[0].unitNumber as string
    );
  formData.append("estate[0].firstName", data.estate[0].firstName);
  formData.append("estate[0].lastName", data.estate[0].lastName);
  formData.append("estate[0].isResident", `${data.estate[0].isResident}`);
  data.estate[0].middleName &&
    formData.append("estate[0].middleName", data.estate[0].middleName);
  formData.append("estate[0].prefix", data.estate[0].prefix);
  formData.append("estate[0].nextRenewalDate", data.estate[0].nextRenewalDate);
  formData.append("estate[0].yearOfEntry", data.estate[0].yearOfEntry);
  formData.append("estate[0].estateId", data.estate[0].estateId);
  formData.append("estate[0].phoneNumber", data.estate[0].phoneNumber);
  formData.append("estate[0].gender", data.estate[0].gender);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.post(url, formData, config);
  return res;
};

export const useCreatePrimaryResident = () => {
  return useMutation(createPrimaryRes);
};
