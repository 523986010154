import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { HelpPagesTable } from "./HelpPagesTable";
import { Link } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

export const FAQContainer: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-end">
        <PermissionRestrictor requiredPermissions={["add-support-page"]}>
          <Link to={routes.createFAQContent}>
            <Button type="primary">Add FAQ</Button>
          </Link>
        </PermissionRestrictor>
      </div>
      <HelpPagesTable category={"faq"} />
    </div>
  );
};
