import { Breadcrumb, Button, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export const PageHeader: React.FC<{
  backLink?: string;
  title: string;
  highlight?: { name: string; link: string };
}> = ({ backLink, title, highlight }) => {
  return (
    <div className="flex gap-2 ">
      {backLink && (
        <Link to={backLink} className="relative top-1">
          <Button icon={<LeftOutlined />} shape="circle" size="small" />
        </Link>
      )}
      <div className="flex flex-col">
        <Typography.Title level={3}>{title}</Typography.Title>
        {highlight && (
          <Link to={highlight?.link}>
            <span className="capitalize text-blue-400 text-base relative -top-3">
              {highlight?.name}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};
