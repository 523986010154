import React from "react";
import { DataActionableCard } from "../../../components/cards/DataActionableCard";
import { useFetchEstates } from "../../estates/hooks/useFetchEstates";
import moment from "moment";

export const EstatesByNextRenewalDate = () => {
  const { data, isLoading } = useFetchEstates({
    pagination: { offset: 0, limit: 10000 },
  });
  return (
    <DataActionableCard
      isLoading={isLoading}
      title="Estate by next renewal date"
      columns={[
        { title: "Estate Name", key: "estateName" },
        { title: "Next Renewal Date", key: "nextRenewalDate" },
      ]}
      data={data?.data.map((item) => ({
        estateName: item.name,
        nextRenewalDate: moment(item.nextRenewalDate).format("YYYY-MM-DD"),
      }))}
    />
  );
};
