import { Button, Col, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  link?: string;
  action?: () => void;
  amount: number;
}

const OverViewCard = ({ title, link, amount, action }: IProps) => {
  return (
    <Col span={24} lg={{ span: 8 }}>
      <div className="bg-[#fff] shadow-md p-4 h-36">
        <div className="space-between align-center">
          <div className="left">
            <Typography.Text>{title}</Typography.Text>
            <Typography.Title level={2} className="txt-blue mt-30 d-block">
              {amount}
            </Typography.Title>
          </div>
          <div className="right d-flex align-center">
            {action && (
              <Button
                type="ghost"
                onClick={() => action()}
                icon={<ArrowRightOutlined className="txt-blue" />}
              />
            )}
            {link && (
              <Link to={link}>
                <Button
                  type="ghost"
                  icon={<ArrowRightOutlined className="txt-blue" />}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default OverViewCard;
