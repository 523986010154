import React, { useState } from "react";
import { CpaatGroupsTable } from "./CpaatGroupsTable";
import { Button } from "antd";
import { CreateGroup } from "./CreateGroup";

export const CpaatGroupContainer = () => {
  type TAction = "create-group";
  const [showM, setShowM] = useState<TAction>();
  const handleClick = (val: TAction) => setShowM(val);
  return (
    <>
      <CreateGroup
        open={showM === "create-group"}
        handleClose={() => setShowM(undefined)}
      />
      <div className="flex flex-col gap-4">
        <div className="flex justify-end gap-3">
          <Button onClick={() => handleClick("create-group")} type="primary">
            Create Group
          </Button>
        </div>
        <CpaatGroupsTable />
      </div>
    </>
  );
};
