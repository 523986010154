import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  selectedEstateID?: string;
};
const initialState: InitialState = {
  selectedEstateID: undefined,
};

const estateSlice = createSlice({
  name: "estate",
  initialState,
  reducers: {
    selectEstate: (state, action: PayloadAction<string | undefined>) => {
      state.selectedEstateID = action.payload;
    },
  },
});

export default estateSlice.reducer;
export const { selectEstate } = estateSlice.actions;
