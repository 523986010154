import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

// TO DO : Ensure this is the correct schema to be used
interface ICreateDataProps {
  groupName: string;
}
export const createData = (vals: { props: ICreateDataProps }) => {
  const { props } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/cpaat-data/groups/${process.env.REACT_APP_CREATE_GROUP_ID}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.post(url, data, config);
  return res;
};

export const useCreateGroup = () => {
  return useMutation(createData);
};
