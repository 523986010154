import React from "react";
import { DataActionableCard } from "../../../components/cards/DataActionableCard";
import { useFetchEstateOwners } from "../../estateOwner/hooks/useFetchEstateOwners";

export const EstateAndEstateOwnersOverview = () => {
  const { data, isLoading } = useFetchEstateOwners({
    pagination: {
      offset: 0,
      limit: 100000, // just fetch all estate owners 4 now
    },
  });
  return (
    <DataActionableCard
      isSearchable
      title="Estate Owners and number of estate owned"
      columns={[
        { title: "Owner  Name", key: "ownerName" },
        { title: "Number of estate owned", key: "estatesOwned" },
      ]}
      data={data?.data.map((item) => ({
        ownerName: `${item.firstName} ${item.lastName}`,
        estatesOwned: `${item.totalEstateOwned}`,
      }))}
      isLoading={isLoading}
    />
  );
};
