import React from "react";
import { useFetchEstateBuildingStats } from "../hooks/useFetchEstateBuildingStats";
import { Row, Skeleton } from "antd";
import OverViewCard from "./OverViewCard";
import { routes } from "../../../constants/routes";

export const OverviewCards: React.FC<{
  estateId: string;
  handleKey: (val: string) => void;
}> = ({ estateId, handleKey }) => {
  const { data, isLoading } = useFetchEstateBuildingStats(estateId);

  return (
    <Skeleton loading={isLoading}>
      <Row gutter={[20, 40]}>
        <OverViewCard
          title="Building Types"
          amount={data?.buildingTypeCount ?? 0}
          link={routes.buildingTypes}
        />
        <OverViewCard
          title="Buildings"
          amount={data?.buildingCount ?? 0}
          action={() => handleKey("Buildings")}
        />
        <OverViewCard title="Number of Units" amount={data?.unitCount ?? 0} />
      </Row>
    </Skeleton>
  );
};
