import { Editor } from "@tinymce/tinymce-react";
import { Input, Select, Space } from "antd";
import React, { useRef } from "react";
import { TCreatePageContent } from "../types";
import { HELP_PAGE_CATEGORIES } from "../constants";

interface IProps {
  handleContent: (props: TCreatePageContent) => void;
  data: TCreatePageContent;
  onlyFAQ?: boolean;
}
export const PageCreator: React.FC<IProps> = ({
  handleContent,
  data,
  onlyFAQ = false,
}) => {
  const editorRef = useRef<any>(null);

  return (
    <div>
      <div className="text-editor">
        <div className="flex">
          <div className="flex flex-col gap-2 mb-4">
            <label className="">Category (optional)</label>

            <Select
              disabled={onlyFAQ}
              className="capitalize"
              allowClear
              onClear={() => handleContent({ ...data, category: undefined })}
              size="small"
              value={data.category}
              onSelect={(val: string) =>
                handleContent({ ...data, category: val })
              }
              options={HELP_PAGE_CATEGORIES.map((val) => ({
                label: val,
                value: val,
              }))}
              placeholder="Category"
            />
          </div>
        </div>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <div>
            <label className="mb-2 ">Title</label>

            <Input
              value={data.title}
              onChange={(e) =>
                handleContent({ ...data, title: e.target.value })
              }
              placeholder="Title"
            />
          </div>
          <div>
            <label className="mb-2 ">Content</label>

            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              onInit={(evt, editor) => {
                if (editorRef && editorRef?.current) {
                  editorRef.current = editor;
                }
              }}
              onChange={(val) => {
                handleContent({
                  ...data,
                  content: (val as unknown as any)?.level?.content,
                });
              }}
              initialValue={data.content ?? null}
              init={{
                height: 200,
                menubar: false,
                // images_upload_url: "postAcceptor.php",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help" +
                  "undo redo | image code",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            {/* <button onClick={log}>Log editor content</button> */}
          </div>
        </Space>
      </div>
    </div>
  );
};
