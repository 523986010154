import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TEstate } from "../types";

export const QUERY_KEY_FOR_ESTATES = "estates";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getEstates = async (
  props: IGetListDataProps & { status?: string; isDeleted?: string }
): Promise<{ data: TEstate[]; total: number }> => {
  const { pagination, searchParams, isDeleted, status } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/estate`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      status,
      isDeleted,
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;
  console.log("results", result);
  const data: TEstate[] = result.map(
    (item: TEstate): TEstate => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchEstates = (
  props: IGetListDataProps & { status?: string; isDeleted?: string } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_ESTATES,
      props.pagination,
      props.searchParams,
      props.status,
      props.isDeleted,
    ],
    () =>
      getEstates({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
