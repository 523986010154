import React, { useState } from "react";
import { useFetchEstates } from "../hooks/useFetchEstates";
import { Select, Spin } from "antd";
import { useDebounce } from "../../../hooks/useDebounce";
import { TEstate } from "../types";

interface IProps {
  onSelect?: (id: string, data?: TEstate) => void;
  onClear?: () => void;
  value?: string;
}

export const SelectEstate: React.FC<IProps> = ({
  value,
  onSelect,
  onClear,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm);

  const { data, isSuccess, isFetching } = useFetchEstates({
    searchParams: {
      name: debouncedSearchTerm,
    },
    pagination: {
      limit: 200, //Temporary Fix: increased limit so all estates will be displayed
      offset: 0,
    },
  });

  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };
  return (
    <div>
      <Select
        value={value}
        placeholder="Select Estate"
        loading={isFetching} //TO DO : this should be added to all custom Fetch Form Inputs
        showSearch
        allowClear
        onClear={() => {
          setSearchTerm("");

          onClear?.();
        }}
        onSearch={handleSearch}
        className="rounded border-slate-400 w-full"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSelect={(val: string) => {
          const estate = data?.data.find((item) => item._id === val);
          onSelect?.(val, estate);
        }}
      >
        {isSuccess ? (
          data.data.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.name}
            </Select.Option>
          ))
        ) : (
          <Select.Option key={"__"} disabled>
            <div className="flex justify-center items-center w-full">
              <Spin size="small" />
            </div>
          </Select.Option>
        )}
      </Select>
    </div>
  );
};
