import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";

export const NoOfBarrierGatesCard = () => {
  return (
    <PrimaryCard
      {...{
        amount: 0,
        title: "Number of barrier gate installed",
        imgUrl: "/assets/images/blue_verified.png",
        items: [],
      }}
    />
  );
};
