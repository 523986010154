export const GENDER_OPTIONS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const BUILDING_OWNERSHIP_OPTIONS = ["bought", "rent"].map((item) => ({
  label: item,
  value: item,
}));

export const PREFIX_OPTIONS = [
  "Mr.",
  "Mrs.",
  "Miss",
  "Dr.",
  "Prof.",
  "Barr.",
  "Engr.",
].map((item) => ({
  label: item,
  value: item,
}));
