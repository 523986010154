import { Input, Button, Form, Spin, Radio } from "antd";

import { useMutation, useQueryClient } from "react-query";
import { genders } from "../../../data";
import { addEstateOwner } from "../../../helpers/estateOwners";
import { openNotification } from "../../../utils/notifcations";
import { FormPhoneInput } from "../../../components/FormPhoneInput";
import {
  emailValidationRules,
  passwordValidationRules,
  textInputValidationRules,
} from "../../../utils/validation";
import { QUERY_KEY_FOR_ESTATE_OWNERS } from "../hooks/useFetchEstateOwners";

interface IProps {
  handleClose: Function;
}

const AddEstateOwner = ({ handleClose }: IProps) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(addEstateOwner);
  const handleSubmit = (data: any) => {
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      { ...data, phoneNumber: `${data.phone.code}-${data.phone.number}` },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The estate owner was created successfully",
            // duration: 0.4,
          });
          handleClose();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_ESTATE_OWNERS],
          });
        },
      }
    );
  };

  return (
    // TO DO: Add Validation
    <Form labelCol={{ span: 24 }} onFinish={handleSubmit} requiredMark={false}>
      <Form.Item
        name={"firstName"}
        label={"First Name"}
        rules={textInputValidationRules}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        label={"Last Name"}
        rules={textInputValidationRules}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item name={"email"} label={"Email"} rules={emailValidationRules}>
        <Input placeholder="Email" />
      </Form.Item>
      <FormPhoneInput Form={Form} />

      <Form.Item
        name={"password"}
        label={"Password"}
        rules={passwordValidationRules}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name={"address"}
        label={"Address"}
        rules={textInputValidationRules}
      >
        <Input.TextArea placeholder="Address" />
      </Form.Item>
      <Form.Item name={"gender"} label={"Gender"} rules={[{ required: true }]}>
        <Radio.Group>
          {genders.map((item) => (
            <Radio key={item} value={item} className="uppercase w-60">
              {item}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="w-full"
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddEstateOwner;
