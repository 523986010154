export const routes = {
  login: "/login",
  home: "/",
  admin: "/adminstration",
  support: "/support",
  faq: "/faq",
  contactSupport: "/contact-support",
  createHelpPage: "/support/create-help-page",
  createFAQContent: "/support/create-faq",
  singleHelpPage: (id?: string) => ({
    format: "/support/help-page/:id",
    path: `/support/help-page/${id}`,
  }),
  helpPagesByCategory: (category?: string) => ({
    format: "/support/help-page/:category/category",
    path: `/support/help-page/${category}/category`,
  }),
  estates: "/estates",
  singleEstate: (id?: string) => ({
    format: "/estates/:id",
    path: `/estates/${id}`,
  }),
  staff: "/staff",
  residents: "/residents",
  building: "/building",
  buildingTypes: "/building-types",
  buildingUnits: "/building-units",
  services: "/services",
  panicAlarms: "/panic-alarms",
  idTemplates: "/id-templates",
  estateOwners: "/estate-owners",
  facilityManagers: "/facility-managers",
  pricingPlans: "/pricing-plans",
  reports: "/reports",
};
