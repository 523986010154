import React from "react";
import { routes } from "../../../constants/routes";
import { PageHeader } from "../../../components/page/PageHeader";
import { CreateHelpPageContainer } from "../components/CreateHelpPageContainer";
import { useQueryClient } from "react-query";
import { openNotification } from "../../../utils/notifcations";
import { QUERY_KEY_FOR_HELP_PAGES } from "../hooks/useGetHelpPages";
import { TCreatePageContent } from "../types";
import { useUpdateHelpPage } from "../hooks/useUpdateHelpPage";
import { useParams } from "react-router-dom";
import {
  QUERY_KEY_FOR_SINGLE_HELP_PAGE,
  useGetSingleHelpPage,
} from "../hooks/useGetSingleHelpPage";

const SingleHelpPage = () => {
  const params = useParams();
  const pageId: string = params.id as unknown as string;
  const queryClient = useQueryClient();

  const { mutate, isLoading: updateLoading } = useUpdateHelpPage();

  const updatePage = (data: TCreatePageContent) => {
    mutate(
      {
        id: pageId,
        props: {
          ...data,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "Help Page updated successfully",
            // duration: 0.4,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_HELP_PAGES]);
          queryClient.invalidateQueries([QUERY_KEY_FOR_SINGLE_HELP_PAGE]);
        },
      }
    );
  };

  const { data, isLoading: isSingleLoading } = useGetSingleHelpPage({
    id: pageId,
  });
  if (data)
    return (
      <div className="flex flex-col gap-4">
        <PageHeader
          title={data?.title ?? ""}
          backLink={routes.support}
          highlight={{
            name: data.category,
            link: routes.helpPagesByCategory(data.category).path,
          }}
        />
        <CreateHelpPageContainer
          isLoading={isSingleLoading}
          defaultPageContent={{
            title: data?.title,
            content: data?.content,
            category: data.category,
          }}
          defaultContentToShow="preview"
          submit={{ fn: updatePage, loading: updateLoading }}
        />
      </div>
    );
  return null;
};

export default SingleHelpPage;
