import { Typography } from "antd";
import React from "react";
import { HelpPagesContainer } from "../components/HelpPagesContainer";
import { useParams } from "react-router-dom";

const HelpPagesByCategoryPage = () => {
  const params = useParams();
  const category: string = params.category as unknown as string;
  return (
    <div className="flex flex-col gap-4">
      <Typography.Title level={3}>
        <span className="capitalize">Support</span>
      </Typography.Title>
      <HelpPagesContainer defaultCategory={category} />
    </div>
  );
};

export default HelpPagesByCategoryPage;
