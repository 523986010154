import React from "react";
import { PageLayout } from "../../../components/page/PageLayout";
import IDTemplatesTable from "../components/IDTemplatesTable";

export const IDTemplates = () => {
  return (
    <PageLayout title="ID Templates">
      <IDTemplatesTable />
    </PageLayout>
  );
};
