import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TServiceRequest } from "../types/service-requests";

export const QUERY_KEY_FOR_SERVICE_REQUESTS = "service-requests";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

export type TRequestStatus = "pending" | "accepted" | "declined";

const getData = async (
  estateId: string,
  props: IGetListDataProps & { status?: TRequestStatus }
): Promise<{ data: TServiceRequest[]; total: number }> => {
  const { pagination, searchParams, status } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/service-request/request/status`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      EstateId: estateId,
    },
    params: {
      field: "requestStatus",
      status,
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: TServiceRequest[] = result.map(
    (item: TServiceRequest): TServiceRequest => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchServiceRequests = (
  estateId?: string,
  props: IGetListDataProps & { status?: TRequestStatus } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_SERVICE_REQUESTS,
      props.pagination,
      props.searchParams,
      props.status,
      estateId,
    ],
    () =>
      getData(estateId ?? "", {
        ...props,
      }),
    {
      enabled: !!estateId,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
