import { Drawer, Typography, Button, Select } from "antd";
import React, { useState } from "react";

import AddStaff from "../components/AddStaff";
import StaffTable from "../components/StaffTable";
import { SelectEstate } from "../../estates/components/SelectEstate";
import { TStaffRole } from "../types";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

export const STAFF_ROLES: TStaffRole[] = [
  "administrator",
  "user-admin",
  "limited",
  "security",
];
export const STAFF_ROLES_OPTIONS = STAFF_ROLES.map((item) => ({
  value: item,
  label: item,
}));

const Staff = () => {
  const [showD, setShowD] = useState(false);
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  const [role, setRole] = useState<TStaffRole>();

  return (
    <div>
      <Drawer visible={showD} onClose={() => setShowD(false)} title="Add Staff">
        <AddStaff handleClose={() => setShowD(false)} />
      </Drawer>
      <Typography.Title level={3}>Staff</Typography.Title>
      {/* content */}
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex justify-between">
          <SelectEstate
            value={selectedEstateId}
            onSelect={(id) => {
              dispatch(selectEstate(id));
            }}
            onClear={() => {
              dispatch(selectEstate(undefined));
            }}
          />

          <Select
            options={STAFF_ROLES_OPTIONS}
            value={role}
            onClear={() => setRole(undefined)}
            onSelect={(val: TStaffRole) => setRole(val)}
            allowClear
            placeholder="Filter by role"
          />
          <PermissionRestrictor requiredPermissions={["create-staff"]}>
            <Button type="primary" onClick={() => setShowD(true)}>
              Add Staff
            </Button>
          </PermissionRestrictor>
        </div>
        {selectedEstateId ? (
          <StaffTable estateId={selectedEstateId} role={role} />
        ) : (
          <div>Please Select an estate</div>
        )}
      </div>
    </div>
  );
};

export default Staff;
