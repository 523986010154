import React from "react";
import { EstateAndEstateOwnersOverview } from "./EstateAndEstateOwnersOverview";
import { EstatesAndFMsOverview } from "./EstatesAndFMsOverview";
import { EstatesAndUsersOverview } from "./EstatesAndUsersOverview";
import { EstatesByNextRenewalDate } from "./EstatesByNextRenewalDate";
import { EstatesCategorizedByPlan } from "./EstatesCategorizedByPlan";
import { MonthlyUsersOverview } from "./MonthlyUsersOverview";
import { NoOfEstatesCard } from "./NoOfEstatesCard";
import { TotalNoOfUsersCard } from "./TotalNoOfUsersCard";
import { NoOfBarrierGatesCard } from "./NoOfBarrierGatesCard";

export const OverviewContainer = () => {
  return (
    <>
      {/* 2 */}
      <div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
          <NoOfEstatesCard key={1} />
          <TotalNoOfUsersCard key={2} />
          <NoOfBarrierGatesCard key={3} />
        </div>
      </div>
      {/* 3 */}
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 ">
        <div className="md:col-span-2">
          <MonthlyUsersOverview key={4} />
        </div>
        <div>
          <EstatesCategorizedByPlan key={5} />
        </div>
      </div>
      {/* 4 */}
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
        <div>
          <EstateAndEstateOwnersOverview key={6} />
        </div>
        <div>
          <EstatesAndFMsOverview key={7} />
        </div>
      </div>
      {/* 5 */}
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
        <div>
          <EstatesAndUsersOverview key={8} />
        </div>
        <div>
          <EstatesByNextRenewalDate key={9} />
        </div>
      </div>
    </>
  );
};
