import { BuildingTypesContainer } from "../components/BuildingTypesContainer";
import { InnerPageHeader } from "../../../components/page/InnerPageHeader";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/routes";

export const BuildingTypes = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-2">
      <InnerPageHeader
        text="Building Types"
        handleClick={() => navigate(routes.building)}
      />
      <BuildingTypesContainer />
    </div>
  );
};
