import { Button, Drawer, Modal, Space, Table } from "antd";
import React, { useState } from "react";

import { EditOutlined } from "@ant-design/icons";

import { ColumnsType } from "antd/lib/table";

import { usePagination } from "../../../hooks/usePagination";
import { useFetchEstates } from "../../estates/hooks/useFetchEstates";
import { IDTemplatePreview } from "./IDTemplatePreview";
import { TEstate } from "../../estates/types";
import EditIDTemplate from "./EditIDTemplate";
enum EAction {
  NO_COMP = "",
  EDIT = "Edit Estate ID Template",
  VIEW = "View ID Template",
}

const IDTemplatesTable = () => {
  const { onChange, pagination } = usePagination();
  const columns: ColumnsType<TEstate> = [
    {
      title: "Estate Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => `${record.name} `,
    },
    {
      title: "Color",
      dataIndex: "Color",
      key: "Color",
      render: (_, record) => `${record.idCardColor} `,
    },
    {
      title: "Preview",
      dataIndex: "Preview",
      key: "Preview",
      render: (_, record) => (
        <Button
          type="text"
          onClick={() =>
            handleComp({
              id: record["_id"],
              action: EAction.VIEW,
              estate: record,
            })
          }
        >
          <span className="text-[#25AAE1] underline">View Template</span>
        </Button>
      ),
    },

    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={() =>
              handleComp({
                id: item["_id"],
                action: EAction.EDIT,
                estate: item,
              })
            }
          />
          {/* <Button
            icon={<DeleteFilled />} //just available for developer
            type="text"
            onClick={() =>
              handleComp({ id: item["_id"], action: EAction.DELETE })
            }
          /> */}
          {/* <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="3"
                  onClick={() =>
                    handleComp({ id: item["_id"], action: EAction.EDIT })
                  }
                >
                  Edit
                </Menu.Item>
              </Menu>
            }
            trigger={["click", "hover"]}
          >
            <MoreOutlined />
          </Dropdown> */}
        </Space>
      ),
    },
  ];
  const { data, isFetching } = useFetchEstates({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });
  const [showD, setShowD] = useState(false);
  const [showM, setShowM] = useState(false);
  const [action, setAction] = useState<EAction>(EAction.NO_COMP);
  const [estate, setEstate] = useState<TEstate>();
  const [id, setId] = useState("");
  const handleComp = ({
    id,
    action,
    estate,
  }: {
    id: string;
    action: EAction;
    estate?: TEstate;
  }) => {
    if (action === EAction.VIEW) {
      setAction(action);
      setId(id);
      setEstate(estate);
      setShowM(true);

      return; //open warning modal , are you sure
    }
    setAction(action);
    setId(id);
    setEstate(estate);
    setShowD(true);
  };
  return (
    <div>
      <Drawer visible={showD} onClose={() => setShowD(false)} title={action}>
        {action === EAction.EDIT && (
          <EditIDTemplate
            id={id}
            handleClose={() => setShowD(false)}
            estate={estate}
          />
        )}
      </Drawer>
      <Modal
        visible={showM}
        onCancel={() => setShowM(false)}
        // title={action}
        footer={null}
        style={{ top: 10 }}
      >
        {action === EAction.VIEW && (
          <IDTemplatePreview
            id={id}
            handleClose={() => setShowD(false)}
            estate={estate}
          />
        )}
      </Modal>

      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};

export default IDTemplatesTable;
