import { Tabs, Typography } from "antd";

import { OverviewContainer } from "../components/OverviewContainer";
import { BuildingsContainer } from "../components/BuildingsContainer";
import { useState } from "react";

export const Buildings = () => {
  const [key, setKey] = useState<string>("Overview");
  const handleKey = (val: string) => {
    setKey(val);
  };
  return (
    <div>
      <Typography.Title level={3}>Buildings</Typography.Title>

      <Tabs
        onChange={(val) => setKey(val)}
        activeKey={key}
        items={[
          {
            key: "Overview",
            label: "Overview",
            children: <OverviewContainer handleKey={handleKey} />,
          },
          {
            key: "Buildings",
            label: "Buildings",
            children: <BuildingsContainer />,
          },
        ]}
      />
    </div>
  );
};
