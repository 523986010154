import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { useFetchEstates } from "../../estates/hooks/useFetchEstates";

export const NoOfEstatesCard = () => {
  const { data, isLoading } = useFetchEstates();
  return (
    <PrimaryCard
      {...{
        amount: data?.total,
        isLoading,
        title: "Number of Estate",
        imgUrl: "/assets/images/blue_book.png",
        items: [],
      }}
    />
  );
};
