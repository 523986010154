import { useEffect, useState } from "react";
import { SelectEstate } from "../../estates/components/SelectEstate";
import { OverviewContainer } from "../components/OverviewContainer";
import { SingleEstateOverview } from "../components/SingleEstateOverview";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchSingleEstate } from "../../estates/hooks/useFetchSingleEstate";
import { openNotification } from "../../../utils/notifcations";
import { routes } from "../../../constants/routes";
import ComponentLoader from "../../../components/loaders/ComponentLoader";

const Overview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedEstateId, setSelectedEstateId] = useState<string>();
  const { isSuccess, data, isError, error, isFetching } = useFetchSingleEstate({
    id: params.id,
  });

  console.log("estate", data);

  useEffect(() => {
    if (params?.id && isSuccess) {
      setSelectedEstateId(params?.id);
      // perform an api check
    }
    if (isError) {
      openNotification({
        state: "error",
        title: "Estate does not exist!",
        description: error.message,
      });
      navigate(routes.home, { replace: true });
    }
  }, [params, isSuccess, isError, navigate, error]);

  if (isFetching) {
    return <ComponentLoader />;
  }

  return (
    <div className="flex flex-col gap-6">
      {/* 1 */}
      <div className="flex justify-start">
        <SelectEstate
          value={selectedEstateId}
          onSelect={(id) => navigate(routes.singleEstate(id).path)}
          onClear={() => {
            setSelectedEstateId(undefined);
            navigate(routes.home);
          }}
        />
      </div>
      {selectedEstateId && <SingleEstateOverview id={selectedEstateId} />}

      {!params?.id && <OverviewContainer />}
    </div>
  );
};

export default Overview;
