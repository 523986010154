import React from "react";
import { ICard } from "./types";
import { CARD_CLASS_NAME } from "../../constants/commonClassNames";
import { Skeleton, Tag } from "antd";

export const PrimaryCard: React.FC<ICard> = ({
  title,
  imgUrl,
  amount,
  isLoading,
  items = [],
}) => {
  return (
    <div className={`${CARD_CLASS_NAME} flex flex-col`}>
      {isLoading ? (
        <Skeleton active round paragraph={{ rows: 4 }} />
      ) : (
        <>
          {/* main */}
          <div className="flex gap-4">
            <img
              alt={title}
              src={imgUrl}
              className="object-contain h-14 w-1h-14"
            />
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold">{title}</h4>
              <p className="font-bold text-3xl">{amount}</p>
            </div>
          </div>
          {/* items */}
          {items.length > 0 ? (
            <div className="flex flex-col gap-3">
              {items?.map((item, i) => (
                <div className="flex justify-between pb-2 border-b">
                  <Tag color={item.bgColor} key={i}>
                    <span
                      style={{ color: item.textColor }}
                      className="uppercase font-semibold"
                    >
                      {item.name}
                    </span>
                  </Tag>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
