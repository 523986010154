import { Input, Button, Form, Spin, Radio } from "antd";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { genders } from "../../../data";
import {
  getEstateOwner,
  updateEstateOwner,
  IEditEOwnerProps,
} from "../../../helpers/estateOwners";
import { openNotification } from "../../../utils/notifcations";
import ComponentLoader from "../../../components/loaders/ComponentLoader";
import { FormPhoneInput } from "../../../components/FormPhoneInput";
import { textInputValidationRules } from "../../../utils/validation";

interface IProps {
  id: string;
  handleClose: Function;
}
const EditEstateOwner = ({ id, handleClose }: IProps) => {
  const queryClient = useQueryClient();
  const { isFetching } = useQuery(
    ["estate-owner", id],
    () => getEstateOwner({ ownerId: id }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      onSuccess: (data) => {
        form.setFieldsValue({
          ...data,
          phone: {
            code: data.phoneNumber.split("-")[0],
            number: data.phoneNumber.split("-")[1],
          },
        });
      },
      select: (res: any) => {
        const result = res.data.data;

        return result;
      },
    }
  );
  const [form] = Form.useForm();
  const { mutate, isLoading } = useMutation(updateEstateOwner);
  const handleSubmit = async (data: any) => {
    const props: IEditEOwnerProps = {
      ...data,
      ownerId: id,
      phoneNumber: `${data.phone.code}-${data.phone.number}`,
    };
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      { ...props },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The estate owner was updated successfully",
            // duration: 0.4,
          });
          handleClose();
          queryClient.invalidateQueries({
            queryKey: ["estate-owners"],
          });
        },
      }
    );
  };
  if (isFetching) {
    return <ComponentLoader />;
  }
  return (
    <Form
      labelCol={{ span: 24 }}
      onFinish={handleSubmit}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        name={"firstName"}
        label={"First Name"}
        rules={textInputValidationRules}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        label={"Last Name"}
        rules={textInputValidationRules}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <FormPhoneInput Form={Form} />

      <Form.Item
        name={"address"}
        label={"Address"}
        rules={textInputValidationRules}
      >
        <Input.TextArea placeholder="Address" />
      </Form.Item>
      <Form.Item name={"gender"} label={"Gender"} rules={[{ required: true }]}>
        <Radio.Group>
          {genders.map((item) => (
            <Radio key={item} value={item} className="uppercase w-60">
              {item}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="w-full"
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditEstateOwner;
