import React, { useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { usePagination } from "../../../hooks/usePagination";
import { Button, Space, Table } from "antd";
import { TService } from "../types/services";
import { EditOutlined } from "@ant-design/icons";

import { useFetchServices } from "../hooks/useFetchServices";
import { EditService } from "./EditService";
interface IProps {
  estateId: string;
}
export const ServicesTable = ({ estateId }: IProps) => {
  const [entityId, setEntityId] = useState<string>();
  const [edit, setEdit] = useState(false);
  const handleEdit = (id: string) => {
    setEntityId(id);
    setEdit(true);
  };
  const { pagination, onChange } = usePagination();
  const { data, isFetching } = useFetchServices(estateId, {
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  const columns: ColumnsType<TService> = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Service Price",
      dataIndex: "serv",
      key: "serv",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record.price}</span>
        </div>
      ),
    },

    {
      title: "Merchant Name",
      dataIndex: "Merchant Name",
      key: "Merchant Name",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span>{record?.merchantName}</span>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "part",
      key: "part",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record?.serviceTypeId?.name}</span>
        </div>
      ),
    },
    {
      title: "Added by",
      dataIndex: "added by",
      key: "added by",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold"> {record?.addedBy?.fullName}</span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <Button
            title="Edit Building"
            icon={<EditOutlined />}
            type="text"
            // onClick={() => handleEdit(item._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {entityId && (
        <EditService
          id={entityId}
          handleClose={() => {
            setEdit(false);
            setEntityId(undefined);
          }}
          open={edit}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
