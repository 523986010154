import { Col, Typography } from "antd";

interface IProps {
  title: string;
  description?: string;
}

const ServiceCategoryCard = ({ title, description }: IProps) => {
  return (
    <Col span={24} lg={{ span: 6 }}>
      <div className="bg-[#fff] shadow-md px-4 py-2 h-20 rounded-md">
        <div className="flex flex-col gap-4">
          <div className="">
            <Typography.Title level={4}>{title}</Typography.Title>
          </div>
          <div>
            <span>{description}</span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ServiceCategoryCard;
