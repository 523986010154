import { SelectEstate } from "../../estates/components/SelectEstate";
import { ServiceCategoryCards } from "./ServiceCategoryCards";
import { useAppDispatch, useAppSelector } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";

export const ServiceCategoryContainer = () => {
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  return (
    <div>
      {/* content */}
      <div className="mt-4 flex flex-col gap-4">
        <div className="flex justify-between">
          <SelectEstate
            value={selectedEstateId}
            onSelect={(id) => {
              dispatch(selectEstate(id));
            }}
            onClear={() => {
              dispatch(selectEstate(undefined));
            }}
          />
        </div>
        {selectedEstateId ? (
          <ServiceCategoryCards estateId={selectedEstateId} />
        ) : (
          <div>Please Select an estate</div>
        )}
      </div>
    </div>
  );
};
