import { CreateHelpPageContainer } from "../components/CreateHelpPageContainer";
import { routes } from "../../../constants/routes";

import { PageHeader } from "../../../components/page/PageHeader";
import { useAddHelpPage } from "../hooks/useAddHelpPage";
import { useQueryClient } from "react-query";
import { TCreatePageContent } from "../types";
import { openNotification } from "../../../utils/notifcations";
import { QUERY_KEY_FOR_HELP_PAGES } from "../hooks/useGetHelpPages";

const CreateFAQPage = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useAddHelpPage();

  const creatPage = (data: TCreatePageContent) => {
    // Default Password is used pending when a proper backend implementation of adding cpaat staff exists
    mutate(
      {
        props: {
          ...data,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "Help Page created successfully",
            // duration: 0.4,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_HELP_PAGES]);
        },
      }
    );
  };
  return (
    <div className="flex flex-col gap-4">
      <PageHeader title="Create FAQ" backLink={routes.faq} />
      <CreateHelpPageContainer
        submit={{ fn: creatPage, loading: isLoading }}
        onlyFAQ
      />
    </div>
  );
};

export default CreateFAQPage;
