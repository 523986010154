import { Typography, Button, ButtonProps } from "antd";
import React from "react";
import EstateOwnerTable from "../../modules/estateOwner/components/EstateOwnerTable";

interface IProps {
  title: string;
  children: React.ReactNode;
  actions?: ButtonProps[];
}

export const PageLayout: React.FC<IProps> = ({ title, actions, children }) => {
  return (
    <>
      <Typography.Title level={3}>{title}</Typography.Title>
      {/* content */}
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex justify-end">
          {actions?.map((item, i) => (
            <Button {...item}>{item.children}</Button>
          ))}
        </div>
        {children}
      </div>
    </>
  );
};
