import {
  Input,
  Button,
  Form,
  Spin,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Upload,
  Typography,
  message,
} from "antd";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import { addEstate, IAddProps } from "../../../helpers/estate";
import { getEstateOwners } from "../../../helpers/estateOwners";
import { getPaystackBanks } from "../../../helpers/paystack";
import { openNotification } from "../../../utils/notifcations";

type TBank = {
  name: string;
  code: string;
};

interface IProps {
  handleClose: Function;
}

const AddEstate = ({ handleClose }: IProps) => {
  const queryClient = useQueryClient();
  const [fileList, setFilelist] = useState<any>([]);

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const { mutate } = useMutation(addEstate);
  const handleSubmit = async (props: any) => {
    const data: IAddProps = {
      ...props,
      logo: fileList[0]?.originFileObj,

      nextRenewalDate: props.nextRenewalDate?.format("YYYY-MM-DD"),
    };
    console.log("result", data);
    // return;
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      { ...data },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The estate was created successfully",
            // duration: 0.4,
          });
          handleClose();
          queryClient.invalidateQueries({
            queryKey: ["estate-owners"],
          });
        },
      }
    );
  };
  const {
    data: owners,
    isError,
    isFetching,
    isSuccess,
  } = useQuery(
    "estate-owners",
    () => getEstateOwners({ limit: "10", offset: "0" }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      select: (res) => {
        const result = res.data.data;
        // openNotification({
        //   state: "success",

        //   title: "Success",
        //   description: "Estate Owners Fetched !",
        //   // duration: 0.4,
        // });
        return result.result;
      },
    }
  );
  const {
    data: banks,

    isSuccess: isBankSuccess,
  } = useQuery("paystack-banks", () => getPaystackBanks(), {
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onError: (err: any) => {
      // show notification
      openNotification({
        state: "error",
        title: "Error Occured",
        description:
          err?.response.data.message ?? err?.response.data.error.message,
      });
    },
    select: (res) => {
      const result = res.data.data;
      console.log("DATA", result);
      const data: TBank[] = result.map(
        (item: any): TBank => ({ name: item.name, code: item.code })
      );
      return data;
    },
  });

  return (
    <Form labelCol={{ span: 24 }} onFinish={handleSubmit}>
      <div className="mb-4">
        <Typography.Text>
          {fileList.length !== 1 ? "Logo:" : "Logo has been inserted"}
        </Typography.Text>
        <br />

        <Upload
          listType="picture"
          fileList={fileList}
          className="avatar-uploader"
          onChange={handleUpload}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFilelist(newFileList);
          }}
          beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
        >
          {fileList.length !== 1 && (
            <Button type="dashed">Upload Estate Logo</Button>
          )}
        </Upload>
      </div>
      <Form.Item name={"estateOwnerId"}>
        <Select placeholder="Estate Owner">
          {isSuccess &&
            owners.map((item: any) => (
              <Select.Option key={item["_id"]} value={item["_id"]}>
                <span>
                  {item.firstName} {item.lastName}
                </span>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item name={"name"}>
        <Input placeholder="name" />
      </Form.Item>
      <Form.Item name={"accountNumber"}>
        <Input placeholder="account Number" />
      </Form.Item>
      {isBankSuccess && (
        <Form.Item name={"bankCode"}>
          <Select
            placeholder="bank Code"
            options={banks.map((item) => ({
              label: item.name,
              value: item.code,
            }))}
          />
        </Form.Item>
      )}

      {/* <Form.Item name={"dependantFee"}>
        <InputNumber placeholder="dependant Fee" style={{ width: "100%" }} />
      </Form.Item> */}
      <Form.Item name={"primaryResidentFee"}>
        <InputNumber
          placeholder="primary Resident Fee"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item name={"nextRenewalDate"}>
        <DatePicker placeholder="next Renewal Date" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name={"location"}>
        <Input.TextArea placeholder="location" />
      </Form.Item>

      <Form.Item name={"description"}>
        <Input.TextArea placeholder="description" />
      </Form.Item>
      <Form.Item
        name={"addGatePassApproval"}
        label="Do you want to add GatePassApproval?"
      >
        <Switch unCheckedChildren="No" checkedChildren="yes" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" className="w-full" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddEstate;
