import axios from "axios";

import { useQuery } from "react-query";
import { TSingleHelpPage } from "../types";

export const QUERY_KEY_FOR_SINGLE_HELP_PAGE = "single-help-page";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getData = async (props: { id: string }): Promise<TSingleHelpPage> => {
  const { id } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/help-page/content/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(url, config);
  const data: TSingleHelpPage = res.data.data;
  return data;
};

export const useGetSingleHelpPage = (props: { id: string }) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_SINGLE_HELP_PAGE, props.id],
    () =>
      getData({
        ...props,
      }),
    {
      enabled: !!props.id,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
