import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";
import { openNotification } from "../../../utils/notifcations";
import { useAddCpaatStaff } from "../hooks/useAddCpaatStaff";
import { QUERY_KEY_FOR_CPAAT_STAFF } from "../hooks/useGetCpaatStaff";
import { useQueryClient } from "react-query";
import {
  emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
} from "../../../utils/validation";
import { useGetCpaatGroups } from "../hooks/useGetCpaatGroups";

const DEFAULT_PASSWORD = "GateHouse123$";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export const AddCpaatStaff: React.FC<IProps> = ({ open, handleClose }) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const { mutate, isLoading } = useAddCpaatStaff();
  const handleSubmit = (data: any) => {
    // Default Password is used pending when a proper backend implementation of adding cpaat staff exists
    mutate(
      {
        props: {
          name: data.name,
          email: data.email,
          password: DEFAULT_PASSWORD,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "Staff created successfully",
            // duration: 0.4,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_CPAAT_STAFF]);

          handleClose();
          form.resetFields();
        },
      }
    );
  };
  const { data: groups } = useGetCpaatGroups();

  return (
    <Modal
      style={{ top: 10 }}
      title="Add Cpaat Staff"
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <div className="mb-6">
        <span className="text-sm ">
          Default Password:{" "}
          <span className="text-blue-500">{DEFAULT_PASSWORD}</span>
        </span>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label="Name" rules={textInputValidationRules}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input />
        </Form.Item>
        {/* <Form.Item name="role" label="Role" rules={generalValidationRules}>
          <Select
            options={[
              "CPAAT SUPERADMIN",
              "IMPLEMENTER",
              "SUPPORT",
              "FINANCE",
            ].map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="groups"
          label="Select Group(s)"
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            options={groups?.data.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
          />
        </Form.Item> */}
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
