import React, { useState } from "react";
import { TCpaatStaff } from "../types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePagination } from "../../../hooks/usePagination";
import { useGetCpaatStaff } from "../hooks/useGetCpaatStaff";
import { SaveCpaatStaffInfo } from "./SaveCpaatStaffInfo";

export const CpaatStaffTable = () => {
  const [cpaatStaff, setCpaatStaff] = useState<TCpaatStaff>();
  const { pagination, onChange } = usePagination();
  const { data, isFetching } = useGetCpaatStaff({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });
  const columns: ColumnsType<TCpaatStaff> = [
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: 300,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{item.email}</span>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "Role",
      width: 300,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold capitalize">{item.cpaatAdminRole}</span>
        </div>
      ),
    },
    {
      title: "Groups",
      dataIndex: "Groups",
      key: "Groups",
      width: 300,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <span className="capitalize">{item.cpaatAdminGroups.join(", ")}</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "_",
      key: "_",
      width: 300,
      render: (_, item) => (
        <Button size="small" type="primary" onClick={() => setCpaatStaff(item)}>
          Update Info
        </Button>
      ),
    },
  ];
  return (
    <div>
      {cpaatStaff && (
        <SaveCpaatStaffInfo
          open={!!cpaatStaff}
          handleClose={() => setCpaatStaff(undefined)}
          cpaatStaff={cpaatStaff}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.data.length }}
        onChange={onChange}
      />
    </div>
  );
};
