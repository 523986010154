import React from "react";
import { useFetchSingleEstateDBstats } from "../../estates/hooks/useFetchSingleEstateDBstats";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";

export const SingleEstateGatePassCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  const { data, isLoading } = useFetchSingleEstateDBstats({ estateId });

  return (
    <PrimaryCard
      {...{
        amount: (data?.gatePass.booked ?? 0) + (data?.gatePass.checkedIn ?? 0),
        isLoading,
        title: "Number of Gate passes today",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "Booked",
            value: `${pluralOrSingular({
              amount: data?.gatePass.booked,
              singular: "gate pass",
              plural: "gate passses",
            })}`,
            bgColor: "#E7FEFF",
            textColor: "#0CCD09",
          },
          {
            name: "Checked in",
            value: `${pluralOrSingular({
              amount: data?.gatePass.checkedIn,
              singular: "gate pass",
              plural: "gate passes",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
