import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TStaff, TStaffRole } from "../types";

export const QUERY_KEY_FOR_STAFF = "staff";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getStaff = async (
  estateId: string,
  props: IGetListDataProps & { role?: TStaffRole }
): Promise<{ data: TStaff[]; total: number }> => {
  const { pagination, searchParams, role } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/staff/estate/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
      role,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: TStaff[] = result.map(
    (item: TStaff): TStaff => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchStaff = (
  estateId: string,
  props: IGetListDataProps & { role?: TStaffRole } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_STAFF,
      props.pagination,
      props.searchParams,
      estateId,
      props.role,
    ],
    () =>
      getStaff(estateId, {
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
