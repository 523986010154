import { Layout } from "antd";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
}

export const DashboardLayout = ({ children }: IProps) => {
  return (
    <>
      <div className="isDesktop">
        <Layout style={{ maxHeight: "100vh", position: "relative" }} hasSider>
          <DashboardSidebar />

          <Layout>
            <DashboardHeader />
            <Content className={`dashboard-content-container`}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};
