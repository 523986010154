import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "react-auth-kit";
import { RequireAuth } from "react-auth-kit";
import Root from "./components/Root";

import { routes } from "./constants/routes";
import Login from "./modules/auth/pages/Login";
import EstateOwners from "./modules/estateOwner/pages/EstateOwners";
import Estates from "./modules/estates/pages/Estates";
import Staff from "./modules/staff/pages/Staff";
import Overview from "./modules/overview/pages/Overview";
import { IDTemplates } from "./modules/idTemplates/pages/IDTemplates";
import { Residents } from "./modules/residents/pages/Residents";
import { Buildings } from "./modules/buildings/pages/Buildings";
import { BuildingTypes } from "./modules/buildings/pages/BuildingTypes";
import { Services } from "./modules/services/pages/Services";
import Administration from "./modules/adminstration/pages/Administration";
import Support from "./modules/support/pages/Support";
import CreateHelpPage from "./modules/support/pages/CreateHelpPage";
import SingleHelpPage from "./modules/support/pages/SingleHelpPage";
import HelpPagesByCategoryPage from "./modules/support/pages/HelpPagesByCategoryPage";
import ContactSupportPage from "./modules/support/pages/ContactSupportPage";
import FAQPage from "./modules/support/pages/FAQPage";
import CreateFAQPage from "./modules/support/pages/CreateFAQPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <AuthProvider
      authType={"localstorage"}
      authName={"cpaat_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path={routes.login} element={<Login />} />
            <Route
              path={routes.home}
              element={
                <RequireAuth loginPath={routes.login}>
                  <Root />
                </RequireAuth>
              }
            >
              <Route index element={<Overview />} />
              <Route path={routes.estates} element={<Estates />} />
              <Route
                path={routes.singleEstate().format}
                element={<Overview />}
              />
              <Route path={routes.admin} element={<Administration />} />
              <Route path={routes.support} element={<Support />} />
              <Route
                path={routes.helpPagesByCategory().format}
                element={<HelpPagesByCategoryPage />}
              />
              <Route
                path={routes.contactSupport}
                element={<ContactSupportPage />}
              />
              <Route path={routes.faq} element={<FAQPage />} />
              <Route
                path={routes.createHelpPage}
                element={<CreateHelpPage />}
              />
              <Route path={routes.faq} element={<FAQPage />} />
              <Route
                path={routes.createFAQContent}
                element={<CreateFAQPage />}
              />
              <Route
                path={routes.singleHelpPage().format}
                element={<SingleHelpPage />}
              />
              <Route path={routes.estateOwners} element={<EstateOwners />} />
              <Route path={routes.staff} element={<Staff />} />
              <Route path={routes.facilityManagers} element={<div />} />
              <Route path={routes.pricingPlans} element={<div />} />
              <Route path={routes.panicAlarms} element={<div />} />
              <Route path={routes.idTemplates} element={<IDTemplates />} />
              <Route path={routes.reports} element={<div />} />
              <Route path={routes.residents} element={<Residents />} />
              <Route path={routes.services} element={<Services />} />
              <Route path={routes.building} element={<Buildings />} />
              <Route path={routes.buildingTypes} element={<BuildingTypes />} />
            </Route>
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
