import { Drawer, Button } from "antd";
import React, { useState } from "react";
import { SelectEstate } from "../../estates/components/SelectEstate";
import AddResident from "./AddResident";
import { ResidentsTable } from "./ResidentsTable";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

export const ResidentsContainer = () => {
  const [showD, setShowD] = useState(false);
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();

  return (
    <div>
      <Drawer
        visible={showD}
        onClose={() => setShowD(false)}
        title="Create Resident"
      >
        <AddResident handleClose={() => setShowD(false)} />
      </Drawer>
      {/* content */}
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex justify-between">
          <SelectEstate
            value={selectedEstateId}
            onSelect={(id) => {
              dispatch(selectEstate(id));
            }}
            onClear={() => {
              dispatch(selectEstate(undefined));
            }}
          />
          <PermissionRestrictor requiredPermissions={["create-resident"]}>
            <Button type="primary" onClick={() => setShowD(true)}>
              Add Resident
            </Button>
          </PermissionRestrictor>
        </div>
        {selectedEstateId ? (
          <ResidentsTable estateId={selectedEstateId} />
        ) : (
          <div>Please Select an estate</div>
        )}
      </div>
    </div>
  );
};
