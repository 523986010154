import React from "react";
import { DataActionableCard } from "../../../components/cards/DataActionableCard";
import { useFetchEstates } from "../../estates/hooks/useFetchEstates";

export const EstatesAndUsersOverview = () => {
  const { data, isLoading } = useFetchEstates({
    pagination: { offset: 0, limit: 10000 },
  });
  return (
    <DataActionableCard
      isSearchable
      isLoading={isLoading}
      title="Number of users per estate"
      columns={[
        { title: "Estate Name", key: "estateName" },
        { title: "Primary Resident", key: "primaryResident" },
        { title: "Full Access dependant", key: "fullAccessDependant" },
        { title: "Limited dependant", key: "limitedDependant" },
      ]}
      data={data?.data.map((item) => ({
        estateName: item.name,
        primaryResident: item.totalPrimaryResident,
        fullAccessDependant: item.totalFullAccessDependant,
        limitedDependant: item.totalLimitedDependant,
      }))}
    />
  );
};
