import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TBuildingType } from "../types";

export const QUERY_KEY_FOR_BUILDING_TYPES = "buildings";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getBuildings = async (
  estateId: string,
  props: IGetListDataProps
): Promise<{ data: TBuildingType[]; total: number }> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/building-type/estate/${estateId}`;

  const config = {
    headers: {
      estateId,
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: TBuildingType[] = result.map(
    (item: TBuildingType): TBuildingType => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchBuildingTypes = (
  estateId?: string,
  props: IGetListDataProps = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_BUILDING_TYPES,
      props.pagination,
      props.searchParams,
      estateId,
    ],
    () =>
      getBuildings(estateId ?? "", {
        ...props,
      }),
    {
      enabled: !!estateId,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
