import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

interface IUpdateStaffProps {
  pageId: string;
}
export const deleteData = (props: IUpdateStaffProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/help-page/content/${props.pageId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.delete(url, config);
  return res;
};

export const useDeleteHelpPage = () => {
  return useMutation(deleteData);
};
