import React from "react";
import { SingleEstateBuildingCard } from "./SingleEstateBuildingCard";
import { SingleEstateResidentsCard } from "./SingleEstateResidentsCard";
import { SingleEstateVisitors4TodayCard } from "./SingleEstateVisitors4TodayCard";
import { SingleEstateGatePassCard } from "./SingleEstateGatePassCard";
import { SingleEstateStaffCard } from "./SingleEstateStaffCard";
import { SingleEstateConfigurationCard } from "./SingleEstateConfigurationCard";

export const SingleEstateOverview: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 ">
        <SingleEstateBuildingCard estateId={id} key={1} />
        <SingleEstateResidentsCard estateId={id} key={2} />
        <SingleEstateVisitors4TodayCard estateId={id} key={3} />
        <SingleEstateGatePassCard estateId={id} key={4} />
        <SingleEstateStaffCard estateId={id} key={5} />
        <SingleEstateConfigurationCard estateId={id} key={6} />
      </div>
    </div>
  );
};
