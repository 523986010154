const BrandLogo = ({ small = false }: { small?: boolean }) => {
  return (
    <div style={{ width: `${small ? "120px" : "140px"}` }}>
      <img
        src={`/assets/brandlogo.png`}
        width={195}
        height={44}
        alt="gh-logo"
      />
    </div>
  );
};

export default BrandLogo;
