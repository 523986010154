import React from "react";
import { TCreatePageContent } from "../types";
// import { Typography } from "antd";
import parse from "html-react-parser";

export const PagePreviewer: React.FC<{ data: TCreatePageContent }> = ({
  data,
}) => {
  const content = typeof data.content === "string" ? data.content : "  No Data";
  return (
    <div className="rounded-md px-4 py-3 shadow-lg bg-white flex flex-col gap-4 min-h-screen">
      {/* <Typography.Title level={3}>{data.title}</Typography.Title> */}
      <div className="html-parse-content">{parse(content)}</div>
    </div>
  );
};
