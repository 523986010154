import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { useFetchBuildings } from "../../buildings/hooks/useFetchBuildings";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";

export const SingleEstateBuildingCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  const { data, isLoading } = useFetchBuildings(estateId);
  const { data: unOccupied } = useFetchBuildings(estateId, {
    isOccupied: false,
  });
  const { data: occupied } = useFetchBuildings(estateId, { isOccupied: true });
  return (
    <PrimaryCard
      {...{
        amount: data?.total,
        isLoading,
        title: "Number of Buildings",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "Occupied",
            value: `${pluralOrSingular({
              amount: occupied?.total,
              singular: "building",
              plural: "buildings",
            })}`,
            bgColor: "#0047FF1A",
            textColor: "#0047FF",
          },
          {
            name: "Unoccupied",
            value: `${pluralOrSingular({
              amount: unOccupied?.total,
              singular: "building",
              plural: "buildings",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
