import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

// TO DO : Ensure this is the correct schema to be used
interface IUpdateBuildingProps {
  number?: string;
  addressLine1?: string;
}
export const updateData = (vals: {
  estateId: string;
  buildingId: string;
  props: IUpdateBuildingProps;
}) => {
  const { buildingId, props, estateId } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/building/${buildingId}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId,
    },
  };

  const res: any = axios.patch(url, data, config);
  return res;
};

export const useUpdateBuilding = () => {
  return useMutation(updateData);
};
