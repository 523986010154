import axios from "axios";
import React from "react";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

interface IUpdateStaffProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phoneNumber?: string;
  homeAddress?: string;
  suffix?: string;
  prefix?: string;
  role?: string;
  middleName?: string;
  accessLevel?: string;
  isActive?: boolean;
  staffId: string;
  photo?: any;
}
export const updateStaff = (props: IUpdateStaffProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/staff/${props.staffId}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let formData = new FormData();
  formData.append("photo", props.photo?.originFileObj);
  // formData.append("estate[0]", JSON.stringify(data.estate[0]));
  // formData.append("estate", data.estate);
  formData.append("firstName", data.firstName as string);
  formData.append("lastName", data.lastName as string);
  formData.append("middleName", data.middleName as string);
  formData.append("prefix", data.prefix as string);
  formData.append("suffix", data.suffix as string);
  formData.append("phoneNumber", data.phoneNumber as string);
  formData.append("gender", data.gender as string);
  formData.append("homeAddress", data.homeAddress as string);
  formData.append("role", data.role as string);
  data.accessLevel &&
    formData.append("estate[0].accessLevel", data.accessLevel);

  const res: any = axios.patch(url, formData, config);
  return res;
};

export const useUpdateStaff = () => {
  return useMutation(updateStaff);
};
