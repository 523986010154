import axios from "axios";

import { useQuery } from "react-query";
import { TPrimaryResident } from "../types/primaryResident";

export const QUERY_KEY_FOR_SINGLE_PRIMARY_RESIDENT = "single-staff";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getStaff = async (props: { id?: string }): Promise<TPrimaryResident> => {
  const { id } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/primary-resident/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(url, config);
  const data: TPrimaryResident = res.data.data;
  return data;
};

export const useFetchSinglePrimaryResident = (props: { id?: string }) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_SINGLE_PRIMARY_RESIDENT, props.id],
    () =>
      getStaff({
        ...props,
      }),
    {
      enabled: !!props.id,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
