import React from "react";
import { Row, Skeleton } from "antd";
import ServiceCategoryCard from "./ServiceCategoryCard";
import { useFetchServiceCategories } from "../hooks/useFetchServiceCategories";

export const ServiceCategoryCards: React.FC<{
  estateId: string;
}> = ({ estateId }) => {
  const { data, isLoading } = useFetchServiceCategories(estateId);

  return (
    <div>
      <Skeleton loading={isLoading}>
        <Row gutter={[20, 40]}>
          {data?.data.map((item) => (
            <ServiceCategoryCard key={item._id} title={item.name} />
          ))}
        </Row>
      </Skeleton>
    </div>
  );
};
