import { TCpaatRole } from "../types/roles";

export const cpaatRoles: TCpaatRole[] = [
  {
    name: "CPAAT SUPERADMIN",
    permissions: [
      // administration
      { permission: "admin-features", active: true, category: "admin" },
      // support
      { permission: "add-support-page", active: true, category: "support" },
      { permission: "edit-support-page", active: true, category: "support" },
      { permission: "delete-support-page", active: true, category: "support" },
      // estate
      { permission: "create-estate", active: true, category: "estate" },
      { permission: "create-estate-owner", active: true, category: "estate" },
      { permission: "create-FM", active: true, category: "estate" },
      //   resident
      { permission: "create-resident", active: true, category: "resident" },
      { permission: "view-resident", active: true, category: "resident" },
      { permission: "edit-resident", active: true, category: "resident" },
      { permission: "delete-resident", active: true, category: "resident" },
      { permission: "email-resident", active: true, category: "resident" },
      { permission: "add-dependant", active: true, category: "resident" },
      { permission: "view-dependants", active: true, category: "resident" },
      {
        permission: "resident-resend-login-details",
        active: true,
        category: "resident",
      },
      //   staff
      { permission: "create-staff", active: true, category: "staff" },
      { permission: "archive-staff", active: true, category: "staff" },
      { permission: "view-staff", active: true, category: "staff" },
      { permission: "edit-staff", active: true, category: "staff" },
      {
        permission: "staff-resend-login-details",
        active: true,
        category: "staff",
      },
      //   building
      { permission: "add-building", active: true, category: "building" },
      { permission: "edit-building", active: true, category: "building" },
      { permission: "add-building-type", active: true, category: "building" },
      { permission: "edit-building-type", active: true, category: "building" },
      {
        permission: "delete-building-type",
        active: true,
        category: "building",
      },
      {
        permission: "add-resident-to-building",
        active: true,
        category: "building",
      },
      //   service
      { permission: "create-service", active: true, category: "service" },
      {
        permission: "create-service-category",
        active: true,
        category: "service",
      },
      { permission: "view-service", active: true, category: "service" },
      {
        permission: "view-service-category",
        active: true,
        category: "service",
      },
      { permission: "edit-service", active: true, category: "service" },
      { permission: "delete-service", active: true, category: "service" },
      {
        permission: "delete-service-category",
        active: true,
        category: "service",
      },
      { permission: "view-service-request", active: true, category: "service" },
      {
        permission: "manage-service-request",
        active: true,
        category: "service",
      },
      //   reports
      { permission: "view-report", active: true, category: "report" },
    ],
  },
  {
    name: "IMPLEMENTER",
    permissions: [
      // administration
      { permission: "admin-features", active: false, category: "admin" },
      // support
      { permission: "add-support-page", active: false, category: "support" },
      { permission: "edit-support-page", active: false, category: "support" },
      { permission: "delete-support-page", active: false, category: "support" },

      // estate
      { permission: "create-estate", active: false, category: "estate" },
      { permission: "create-estate-owner", active: false, category: "estate" },
      { permission: "create-FM", active: false, category: "estate" },
      //   resident
      { permission: "create-resident", active: true, category: "resident" },
      { permission: "view-resident", active: true, category: "resident" },
      { permission: "edit-resident", active: true, category: "resident" },
      { permission: "delete-resident", active: true, category: "resident" },
      { permission: "email-resident", active: true, category: "resident" },
      { permission: "add-dependant", active: true, category: "resident" },
      { permission: "view-dependants", active: true, category: "resident" },
      {
        permission: "resident-resend-login-details",
        active: true,
        category: "resident",
      },
      //   staff
      { permission: "create-staff", active: true, category: "staff" },
      { permission: "archive-staff", active: true, category: "staff" },
      { permission: "view-staff", active: true, category: "staff" },
      { permission: "edit-staff", active: true, category: "staff" },
      {
        permission: "staff-resend-login-details",
        active: true,
        category: "staff",
      },
      //   building
      { permission: "add-building", active: true, category: "building" },
      { permission: "edit-building", active: true, category: "building" },
      { permission: "add-building-type", active: true, category: "building" },
      { permission: "edit-building-type", active: true, category: "building" },
      {
        permission: "delete-building-type",
        active: true,
        category: "building",
      },
      {
        permission: "add-resident-to-building",
        active: true,
        category: "building",
      },
      //   service
      { permission: "create-service", active: true, category: "service" },
      {
        permission: "create-service-category",
        active: true,
        category: "service",
      },
      { permission: "view-service", active: true, category: "service" },
      {
        permission: "view-service-category",
        active: true,
        category: "service",
      },
      { permission: "edit-service", active: true, category: "service" },
      { permission: "delete-service", active: true, category: "service" },
      {
        permission: "delete-service-category",
        active: true,
        category: "service",
      },
      { permission: "view-service-request", active: true, category: "service" },
      {
        permission: "manage-service-request",
        active: true,
        category: "service",
      },
      //   reports
      { permission: "view-report", active: true, category: "report" },
    ],
  },
  {
    name: "SUPPORT",
    permissions: [
      // administration
      { permission: "admin-features", active: false, category: "admin" },
      // support
      { permission: "add-support-page", active: false, category: "support" },
      { permission: "edit-support-page", active: true, category: "support" },
      { permission: "delete-support-page", active: true, category: "support" },
      // estate
      { permission: "create-estate", active: false, category: "estate" },
      { permission: "create-estate-owner", active: false, category: "estate" },
      { permission: "create-FM", active: false, category: "estate" },
      //   resident
      { permission: "create-resident", active: false, category: "resident" },
      { permission: "view-resident", active: true, category: "resident" },
      { permission: "edit-resident", active: false, category: "resident" },
      { permission: "delete-resident", active: false, category: "resident" },
      { permission: "email-resident", active: false, category: "resident" },
      { permission: "add-dependant", active: false, category: "resident" },
      { permission: "view-dependants", active: true, category: "resident" },
      {
        permission: "resident-resend-login-details",
        active: false,
        category: "resident",
      },
      //   staff
      { permission: "create-staff", active: false, category: "staff" },
      { permission: "archive-staff", active: false, category: "staff" },
      { permission: "view-staff", active: true, category: "staff" },
      { permission: "edit-staff", active: false, category: "staff" },
      {
        permission: "staff-resend-login-details",
        active: false,
        category: "staff",
      },
      //   building
      { permission: "add-building", active: false, category: "building" },
      { permission: "edit-building", active: false, category: "building" },
      { permission: "add-building-type", active: false, category: "building" },
      { permission: "edit-building-type", active: false, category: "building" },
      {
        permission: "delete-building-type",
        active: false,
        category: "building",
      },
      {
        permission: "add-resident-to-building",
        active: false,
        category: "building",
      },
      //   service
      { permission: "create-service", active: false, category: "service" },
      {
        permission: "create-service-category",
        active: false,
        category: "service",
      },
      { permission: "view-service", active: true, category: "service" },
      {
        permission: "view-service-category",
        active: true,
        category: "service",
      },
      { permission: "edit-service", active: false, category: "service" },
      { permission: "delete-service", active: false, category: "service" },
      {
        permission: "delete-service-category",
        active: false,
        category: "service",
      },
      { permission: "view-service-request", active: true, category: "service" },
      {
        permission: "manage-service-request",
        active: false,
        category: "service",
      },
      //   reports
      { permission: "view-report", active: true, category: "report" },
    ],
  },
  {
    name: "FINANCE",
    permissions: [
      // administration
      { permission: "admin-features", active: false, category: "admin" },
      // support
      { permission: "add-support-page", active: false, category: "support" },
      { permission: "edit-support-page", active: false, category: "support" },
      { permission: "delete-support-page", active: false, category: "support" },
      // estate
      { permission: "create-estate", active: false, category: "estate" },
      { permission: "create-estate-owner", active: false, category: "estate" },
      { permission: "create-FM", active: false, category: "estate" },
      //   resident
      { permission: "create-resident", active: false, category: "resident" },
      { permission: "view-resident", active: true, category: "resident" },
      { permission: "edit-resident", active: false, category: "resident" },
      { permission: "delete-resident", active: false, category: "resident" },
      { permission: "email-resident", active: false, category: "resident" },
      { permission: "add-dependant", active: false, category: "resident" },
      { permission: "view-dependants", active: true, category: "resident" },
      {
        permission: "resident-resend-login-details",
        active: false,
        category: "resident",
      },
      //   staff
      { permission: "create-staff", active: false, category: "staff" },
      { permission: "archive-staff", active: false, category: "staff" },
      { permission: "view-staff", active: true, category: "staff" },
      { permission: "edit-staff", active: false, category: "staff" },
      {
        permission: "staff-resend-login-details",
        active: false,
        category: "staff",
      },
      //   building
      { permission: "add-building", active: false, category: "building" },
      { permission: "edit-building", active: false, category: "building" },
      { permission: "add-building-type", active: false, category: "building" },
      { permission: "edit-building-type", active: false, category: "building" },
      {
        permission: "delete-building-type",
        active: false,
        category: "building",
      },
      {
        permission: "add-resident-to-building",
        active: false,
        category: "building",
      },
      //   service
      { permission: "create-service", active: false, category: "service" },
      {
        permission: "create-service-category",
        active: false,
        category: "service",
      },
      { permission: "view-service", active: true, category: "service" },
      {
        permission: "view-service-category",
        active: true,
        category: "service",
      },
      { permission: "edit-service", active: false, category: "service" },
      { permission: "delete-service", active: false, category: "service" },
      {
        permission: "delete-service-category",
        active: false,
        category: "service",
      },
      { permission: "view-service-request", active: true, category: "service" },
      {
        permission: "manage-service-request",
        active: false,
        category: "service",
      },
      //   reports
      { permission: "view-report", active: true, category: "report" },
    ],
  },
];
