import { Avatar, Button, Drawer, Space, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined } from "@ant-design/icons";
import EditEstate from "./EditEstate";
import { TEstate } from "../types";
import { usePagination } from "../../../hooks/usePagination";
import { useFetchEstates } from "../hooks/useFetchEstates";
import { useFetchEstateOwners } from "../../estateOwner/hooks/useFetchEstateOwners";
import { Link } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

enum EAction {
  NO_COMP = "",
  EDIT = "Edit Estate Details",
}

const EstateTable = () => {
  const { pagination, onChange } = usePagination();

  const { data, isFetching } = useFetchEstates({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });
  const {
    data: owners,

    isSuccess: oIsSuccess,
  } = useFetchEstateOwners();
  const columns: ColumnsType<TEstate> = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => <Avatar src={logo} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <Link to={routes.singleEstate(item._id).path}>{item.name}</Link>
      ),
    },

    {
      title: "Address",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
    },
    {
      title: "Owner Name",
      dataIndex: "ownerId",
      key: "ownerId",
      filters: oIsSuccess
        ? owners?.data?.map((item) => ({
            text: `${item.firstName} ${item.lastName}`,
            value: item["_id"],
          }))
        : [],
      onFilter: (value: any, record) =>
        record.estateOwnerId._id.indexOf(value) !== -1,
      render: (value, record) =>
        `${record.estateOwnerId.firstName} ${record.estateOwnerId.lastName}`,
    },
    {
      title: "Next Renewal Date",
      dataIndex: "nextRenewalDate",
      key: "nextRenewalDate",
      render: (value, record) =>
        `${moment(record.nextRenewalDate).format("YYYY-MM-DD")}`,
    },
    {
      title: "Owner Phone",
      dataIndex: "ownerPhone",
      key: "ownerPhone",
      render: (value, record) => `${record.estateOwnerId.phoneNumber}`,
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (val: string, item: any) => (
        <Space align="center" className="cursor-pointer">
          <PermissionRestrictor requiredPermissions={["create-estate"]}>
            <Button
              icon={<EditOutlined />}
              type="text"
              onClick={() =>
                handleComp({ id: item["_id"], action: EAction.EDIT })
              }
            />
          </PermissionRestrictor>
          {/* <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="3"
                  onClick={() =>
                    handleComp({ id: item["_id"], action: EAction.EDIT })
                  }
                >
                  Edit
                </Menu.Item>
              </Menu>
            }
            trigger={["click", "hover"]}
          >
            <MoreOutlined />
          </Dropdown> */}
        </Space>
      ),
    },
  ];

  const [showD, setShowD] = useState(false);
  const [action, setAction] = useState<EAction>(EAction.NO_COMP);
  const [id, setId] = useState("");
  const handleComp = ({ id, action }: { id: string; action: EAction }) => {
    setAction(action);
    setId(id);
    setShowD(true);
  };

  return (
    <div>
      <Drawer visible={showD} onClose={() => setShowD(false)} title={action}>
        {action === EAction.EDIT && (
          <EditEstate id={id} handleClose={() => setShowD(false)} />
        )}
      </Drawer>
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};

export default EstateTable;
