import axios from "axios";
import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TPrimaryResident } from "../types/primaryResident";

export const QUERY_KEY_FOR_ESTATE_PRIMARY_RESIDENTS = "primary-residents";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getEstatePrimaryResidents = async (
  estateId: string,
  props: IGetListDataProps
): Promise<{ data: TPrimaryResident[]; total: number }> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/primary-resident/estate/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const retData: TPrimaryResident[] = result.map(
    (item: TPrimaryResident): TPrimaryResident => ({
      ...item, //adheres to backend
    })
  );
  const data = retData.filter((item) => item?.estate);

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchEstatePrimaryResidents = (
  estateId: string,
  props: IGetListDataProps = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_ESTATE_PRIMARY_RESIDENTS,
      props.pagination,
      props.searchParams,
      estateId,
    ],
    () =>
      getEstatePrimaryResidents(estateId, {
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
