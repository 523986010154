export const MONTH_LABELS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Nov",
  "Dec",
];

export const PLAN_LABELS = ["Basic", "Advanced", "Enterprise"];
export const PLAN_BG_COLORS = ["#FF8F6B", "#5B93FF", "#0CCD09"];
