import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

interface IUpdateStaffProps {
  residentId: string;
  estateId: string;
}
export const deleteRes = (props: IUpdateStaffProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/primary-resident/${props.residentId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId: props.estateId,
    },
  };

  const res: any = axios.delete(url, config);
  return res;
};

export const useDeletePrimaryResident = () => {
  return useMutation(deleteRes);
};
