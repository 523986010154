import { Layout, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BrandLogo from "../BrandLogo";
import {
  DBIcon,
  ResidentsIcon,
  VisitorsIcon,
  ScannedPassIcon,
  GatePassIcon,
  BuildingIcon,
  LogoutIcon,
} from "../../icons";
import { routes } from "../../constants/routes";
import { useSignOut } from "react-auth-kit";
import { useState } from "react";
import { useAuthData } from "../../hooks/useAuthData";
import { canUserAccessComponent } from "../permission-restrictor/PermissionRestrictor";

const { Sider } = Layout;

const NAV_ITEM_MARGIN_LEFT = `ml-4`;
const INNER_ITEM_CIRCLE = `h-2 w-2 rounded-full bg-white`;

const DashboardSidebar = () => {
  const { pathname: pathName } = useLocation();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut();
    navigate("/login");
  };
  const { user } = useAuthData();

  const [showEstateItems, setShowEstateItems] = useState(false);
  const [showSupportItems, setShowSupportItems] = useState(false);
  return (
    <Sider className="custom-sidebar">
      <Menu theme="dark" mode="vertical">
        <Menu.Item key="0" className="v-nav-item-center  mt-side-logo ">
          <Link to={routes.home}>
            <div>
              <BrandLogo />
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="1"
          icon={<DBIcon />}
          className={`v-nav-item-center ${pathName === "/" ? "active" : ""}`}
        >
          <Link to={"/"}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Dashboard</div>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="admin"
          icon={<ScannedPassIcon />}
          className={`v-nav-item-center ${
            pathName === routes.admin ? "active" : ""
          }`}
          // hidden={
          //   canUserAccessComponent({
          //     userRoleName: user.cpaatAdminRole,
          //     requiredPermissions: ["admin-features"],
          //   }) === false
          //     ? true
          //     : false
          // }
        >
          <Link to={routes.admin}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Adminstration</div>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="2"
          icon={<ResidentsIcon />}
          className={`v-nav-item-center ${
            pathName === routes.estateOwners ? "active" : ""
          }`}
        >
          <Link to={routes.estateOwners}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Estate Owners</div>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="3"
          icon={<BuildingIcon />}
          className={`v-nav-item-center ${
            pathName === routes.estates ? "active" : ""
          }`}
          onClick={() => setShowEstateItems((val) => !val)}
        >
          <Link to={routes.estates}>
            <div
              className={`${NAV_ITEM_MARGIN_LEFT} flex justify-between items-end`}
            >
              <span>Estates</span>
              {showEstateItems ? (
                <i className="ri-arrow-up-s-line text-white ml-4" />
              ) : (
                <i className="ri-arrow-down-s-line text-white ml-4" />
              )}
            </div>
          </Link>
        </Menu.Item>
        {showEstateItems && (
          <>
            <Menu.Item
              key="residents"
              icon={<div />}
              className={`v-nav-item-center ${
                pathName === routes.residents ? "active" : ""
              }`}
            >
              <Link to={routes.residents}>
                <div
                  className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                >
                  <div className={INNER_ITEM_CIRCLE} /> Residents
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="buildings"
              icon={<div />}
              className={`v-nav-item-center ${
                pathName === routes.building ? "active" : ""
              }`}
            >
              <Link to={routes.building}>
                <div
                  className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                >
                  <div className={INNER_ITEM_CIRCLE} />
                  Building
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="Services"
              icon={<div />}
              className={`v-nav-item-center ${
                pathName === routes.services ? "active" : ""
              }`}
            >
              <Link to={routes.services}>
                <div
                  className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                >
                  <div className={INNER_ITEM_CIRCLE} />
                  Services
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="Panic Alarm"
              icon={<div />}
              className={`v-nav-item-center ${
                pathName === routes.panicAlarms ? "active" : ""
              }`}
            >
              <Link to={routes.panicAlarms}>
                <div
                  className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                >
                  <div className={INNER_ITEM_CIRCLE} />
                  Panic Alarm
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="ID Template"
              icon={<div />}
              className={`v-nav-item-center ${
                pathName === routes.idTemplates ? "active" : ""
              }`}
            >
              <Link to={routes.idTemplates}>
                <div
                  className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                >
                  <div className={INNER_ITEM_CIRCLE} />
                  ID Template
                </div>
              </Link>
            </Menu.Item>
          </>
        )}

        <Menu.Item
          key="4"
          icon={<VisitorsIcon />}
          className={`v-nav-item-center ${
            pathName === routes.staff ? "active" : ""
          }`}
        >
          <Link to={routes.staff}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Staff</div>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<VisitorsIcon />}
          className={`v-nav-item-center ${
            pathName === routes.facilityManagers ? "active" : ""
          }`}
        >
          <Link to={routes.facilityManagers}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Facility Managers</div>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<GatePassIcon />}
          className={`v-nav-item-center ${
            pathName === routes.pricingPlans ? "active" : ""
          }`}
        >
          <Link to={routes.pricingPlans}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Pricing Plans</div>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="7"
          icon={<ScannedPassIcon />}
          className={`v-nav-item-center ${
            pathName === routes.reports ? "active" : ""
          }`}
        >
          <Link to={routes.reports}>
            <div className={NAV_ITEM_MARGIN_LEFT}>Reports</div>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="support"
          icon={<GatePassIcon />}
          className={`v-nav-item-center ${
            pathName === routes.support ? "active" : ""
          }`}
          onClick={() => setShowSupportItems((val) => !val)}
        >
          <Link to={routes.support}>
            <div
              className={`${NAV_ITEM_MARGIN_LEFT} flex justify-between items-end`}
            >
              <span>Support</span>
              {showSupportItems ? (
                <i className="ri-arrow-up-s-line text-white ml-4" />
              ) : (
                <i className="ri-arrow-down-s-line text-white ml-4" />
              )}
            </div>
          </Link>
        </Menu.Item>
        {showSupportItems && (
          <>
            {[
              { key: "help pages", link: routes.support, label: "Help Pages" },
              {
                key: "Frequently Asked Questions",
                link: routes.faq,
                label: "FAQ",
              },
              {
                key: "Contact Support",
                link: routes.contactSupport,
                label: "Contact Support",
              },
            ].map((item, i) => (
              <Menu.Item
                key={item.key}
                icon={<div />}
                className={`v-nav-item-center ${
                  pathName === item.link ? "active" : ""
                }`}
              >
                <Link to={item.link}>
                  <div
                    title={item.key}
                    className={`${NAV_ITEM_MARGIN_LEFT} flex gap-2 items-center`}
                  >
                    <div className={INNER_ITEM_CIRCLE} /> {item.label}
                  </div>
                </Link>
              </Menu.Item>
            ))}
          </>
        )}
        <Menu.Item
          key="logout"
          icon={<LogoutIcon />}
          className={`v-nav-item-center mt-16 `}
          onClick={(e) => {
            if (window.confirm("Are you sure?")) {
              handleLogout();
            }
          }}
        >
          <div className={NAV_ITEM_MARGIN_LEFT}>Logout</div>
        </Menu.Item>

        <Menu.Item key="12" className={`v-nav-item-center `}>
          <span />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default DashboardSidebar;
