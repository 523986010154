import { Button, Drawer, Space, Table } from "antd";
import React, { useState } from "react";

import { EditOutlined, DeleteFilled } from "@ant-design/icons";
import EditEstateOwner from "./EditEstateOwner";

import { ColumnsType } from "antd/lib/table";
import { IEstateOwner } from "../types";
import { useFetchEstateOwners } from "../hooks/useFetchEstateOwners";
import { usePagination } from "../../../hooks/usePagination";

enum EAction {
  NO_COMP = "",
  EDIT = "Edit Estate Owner Details",
  DELETE = "Delete Estate Owner",
}

const EstateOwnerTable = () => {
  const { onChange, pagination } = usePagination();
  const columns: ColumnsType<IEstateOwner> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    // {
    //   title: "Archived",
    //   dataIndex: "isArchived",
    //   key: "isArchived",
    //   render: (_: any, record: any) => `${record.isArchived ? "Yes" : "No"} `,
    // },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={() =>
              handleComp({ id: item["_id"], action: EAction.EDIT })
            }
          />
          {/* <Button
            icon={<DeleteFilled />} //just available for developer
            type="text"
            onClick={() =>
              handleComp({ id: item["_id"], action: EAction.DELETE })
            }
          /> */}
          {/* <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="3"
                  onClick={() =>
                    handleComp({ id: item["_id"], action: EAction.EDIT })
                  }
                >
                  Edit
                </Menu.Item>
              </Menu>
            }
            trigger={["click", "hover"]}
          >
            <MoreOutlined />
          </Dropdown> */}
        </Space>
      ),
    },
  ];
  const { data, isFetching } = useFetchEstateOwners({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });
  const [showD, setShowD] = useState(false);
  const [action, setAction] = useState<EAction>(EAction.NO_COMP);
  const [id, setId] = useState("");
  const handleComp = ({ id, action }: { id: string; action: EAction }) => {
    if (action === EAction.DELETE) {
      return; //open warning modal , are you sure
    }
    setAction(action);
    setId(id);
    setShowD(true);
  };
  return (
    <div>
      <Drawer visible={showD} onClose={() => setShowD(false)} title={action}>
        {action === EAction.EDIT && (
          <EditEstateOwner id={id} handleClose={() => setShowD(false)} />
        )}
      </Drawer>

      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};

export default EstateOwnerTable;
