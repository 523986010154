import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

export const InnerPageHeader = ({
  text,
  handleClick,
}: {
  text: string;
  handleClick: () => void;
}) => {
  return (
    <Space size={"large"} align="center" className="mb-40">
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={handleClick}
        shape="circle"
      />
      <Typography.Title level={3} style={{ position: "relative", top: "5px" }}>
        {text}
      </Typography.Title>
    </Space>
  );
};
