import axios from "axios";
import { useMutation } from "react-query";
import { TCreatePageContent } from "../types";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

export const createData = (vals: { props: TCreatePageContent }) => {
  const { props } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/help-page`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.post(url, data, config);
  return res;
};

export const useAddHelpPage = () => {
  return useMutation(createData);
};
