import axios from "axios";
import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TAllEstateResidentData } from "../types";

export const QUERY_KEY_FOR_ALL_ESTATE_RESIDENTS = "all-estate-residents";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getAllEstateResidents = async (
  estateId: string,
  props: IGetListDataProps
): Promise<TAllEstateResidentData> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/estate/all-residents/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  // console.log(fetchedData, "...", res.data);

  const data: TAllEstateResidentData = {
    dependants: fetchedData?.dependants,
    primaryResidents: fetchedData?.primaryResidents,
    totalCount: fetchedData?.totalCount,
  };

  return data;
};

export const useFetchAllEstateResidents = (
  estateId: string,
  props: IGetListDataProps = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_ALL_ESTATE_RESIDENTS,
      props.pagination,
      props.searchParams,
      estateId,
    ],
    () =>
      getAllEstateResidents(estateId, {
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
