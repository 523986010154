import React from "react";
import { DataActionableCard } from "../../../components/cards/DataActionableCard";

export const EstatesAndFMsOverview = () => {
  return (
    <DataActionableCard
      isSearchable
      title="FM Companies and Estates they manage"
      columns={[
        { title: "FM Company", key: "fmCompany" },
        { title: "Number of estate Managed", key: "estatesManaged" },
      ]}
      data={[
        {
          fmCompany: "Dummy Estate",
          estatesManaged: "300",
        },
        {
          fmCompany: "Dummy Estate",
          estatesManaged: "100",
        },
      ]}
    />
  );
};
