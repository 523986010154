import { Table } from "antd";
import React from "react";
import { TCpaatPermission, TCpaatRole } from "../types/roles";
import { ColumnsType } from "antd/lib/table";
import { cpaatRoles } from "../constants";

export const CpaatRolesPermissionsContainer = () => {
  const columns: ColumnsType<TCpaatRole> = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Active Permission Count",
      dataIndex: "perCo",
      key: "perCo",
      render: (_, val) => val.permissions.filter((item) => item.active).length,
    },
  ];
  const expandedRowRender = (role: TCpaatRole) => {
    const permissionsColumns: ColumnsType<TCpaatPermission> = [
      {
        title: "Permission",
        dataIndex: "permission",
        key: "permission",
        render(value, record, index) {
          return (
            <span className="capitalize text-blue-400">
              {record.permission.split("-").join(" ")}
            </span>
          );
        },
      },
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render(_, record) {
          return record.active ? "Yes" : "No";
        },
      },
      { title: "Category", dataIndex: "category", key: "category" },
    ];

    return (
      <Table<TCpaatPermission>
        dataSource={role.permissions}
        columns={permissionsColumns}
        pagination={{ total: role.permissions.length, pageSize: 5 }}
        size="small"
      />
    );
  };

  return (
    <div className="bg-white p-4 rounded-md mb-4">
      <Table
        columns={columns}
        dataSource={cpaatRoles.map((item) => ({ ...item, key: item.name }))}
        size="small"
        pagination={false}
        expandedRowRender={expandedRowRender}
      />
    </div>
  );
};
