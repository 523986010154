import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { HelpPagesTable } from "./HelpPagesTable";
import { Link } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { HELP_PAGE_CATEGORIES } from "../constants";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

export const HelpPagesContainer: React.FC<{ defaultCategory?: string }> = ({
  defaultCategory,
}) => {
  const [category, setCategory] = useState<string>();
  useEffect(() => {
    setCategory(defaultCategory);
  }, [defaultCategory]);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="">
          <Select
            className="capitalize"
            allowClear
            onClear={() => setCategory(undefined)}
            size="small"
            value={category}
            onSelect={(val: string) => setCategory(val)}
            options={HELP_PAGE_CATEGORIES.filter((item) => item !== "faq").map(
              (val) => ({
                label: val,
                value: val,
              })
            )}
            placeholder="Category"
          />
        </div>
        <PermissionRestrictor requiredPermissions={["add-support-page"]}>
          <Link to={routes.createHelpPage}>
            <Button type="primary">Add Help Page</Button>
          </Link>
        </PermissionRestrictor>
      </div>
      <HelpPagesTable category={category} />
    </div>
  );
};
