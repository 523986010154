import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Button,
  Input,
  Form,
  Radio,
  message,
  Upload,
  Avatar,
  Drawer,
  Skeleton,
  DatePicker,
} from "antd";

import { RcFile } from "antd/es/upload/interface";

import { FormPhoneInput } from "../../../components/FormPhoneInput";
import { emailValidationRules } from "../../../utils/validation";

import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";
import {
  QUERY_KEY_FOR_SINGLE_PRIMARY_RESIDENT,
  useFetchSinglePrimaryResident,
} from "../hooks/useFetchSinglePrimaryResident";
import { useUpdatePrimaryResident } from "../hooks/useUpdatePimaryResident";
import moment from "moment";
import {
  BUILDING_OWNERSHIP_OPTIONS,
  GENDER_OPTIONS,
  PREFIX_OPTIONS,
} from "../../../constants/common";

interface IProps {
  handleClose: Function;
  id: string;
  open: boolean;
}

const EditResident = ({ id, handleClose, open }: IProps) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const [fileList, setFilelist] = useState<any>([]);

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const {
    data: entity,
    isFetching,
    isSuccess,
  } = useFetchSinglePrimaryResident({ id });
  const resident = entity?.estate[0];
  useEffect(() => {
    if (isSuccess) {
      const data = entity.estate[0];
      form.setFieldsValue({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: {
          code:
            entity &&
            (data.phoneNumber.split("-")[0].length <= 4
              ? data.phoneNumber.split("-")[0]
              : ""),
          number:
            entity &&
            (data.phoneNumber.split("-")[0].length <= 4
              ? data.phoneNumber.split("-")[1]
              : data.phoneNumber.split("-")[0]),
        },
        gender: data.gender,
        middleName: data?.middleName === "undefined" ? "" : data?.middleName,
        phoneNumber: data.phoneNumber,
        prefix: data.prefix,
        yearOfEntry: moment(`${data.yearOfEntry}-01-01`),
        nextRenewalDate: moment(data.nextRenewalDate),
        ownershipType: data.building[0].ownershipType,
      });
    }
  }, [isSuccess, entity, form]);

  const { mutate, isLoading } = useUpdatePrimaryResident();
  const handleFinish = (data: any) => {
    mutate(
      {
        ...data,
        prefix: data.prefix,
        residentId: id,
        phoneNumber: `${data.phone.code}-${data.phone.number}`,
        yearOfEntry: data.yearOfEntry.format("YYYY"),
        nextRenewalDate: data.nextRenewalDate.format("YYYY-MM-DD"),

        photo: fileList[0],
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: () => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The entity was updated successfully",
            // duration: 0.4,
          });
          form.resetFields();
          setFilelist([]);
          handleClose();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_SINGLE_PRIMARY_RESIDENT],
          });
        },
      }
    );
  };
  return (
    <Drawer visible={open} onClose={() => handleClose()} title="Edit Resident">
      <Typography.Title level={5}>Edit Information</Typography.Title>

      <Skeleton
        active
        paragraph={{ rows: 12 }}
        avatar
        loading={isFetching && !isSuccess}
      >
        <Form
          labelCol={{ span: 24 }}
          requiredMark={false}
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={[30, 5]}>
            <Col span={24} className="">
              <Typography.Text>
                {fileList.length !== 1
                  ? "Profile Photo:"
                  : "Profile image has been inserted"}
              </Typography.Text>
              <br />

              <div
                className={`d-flex align-center w-full ${
                  fileList.length === 0 ? "space-between" : "justify-stretch"
                }`}
              >
                <Upload
                  listType="picture"
                  fileList={fileList}
                  className="avatar-uploader w-full"
                  onChange={handleUpload}
                  onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFilelist(newFileList);
                  }}
                  beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
                >
                  {fileList.length !== 1 && (
                    <Button type="dashed">Upload profile image</Button>
                  )}
                </Upload>
                {fileList.length === 0 && (
                  <Avatar src={resident?.photo} size={72} shape="square" />
                )}
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Prefix"
                name={"prefix"}
                rules={[
                  {
                    required: true,
                    message: "Enter prefix",
                  },
                ]}
              >
                <Select options={PREFIX_OPTIONS} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name={"firstName"}
                rules={[
                  {
                    required: true,
                    message: "Enter first name",
                  },
                ]}
              >
                <Input placeholder="first name"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                rules={[
                  {
                    required: true,
                    message: "Enter last name",
                  },
                ]}
              >
                <Input placeholder="last name"></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Middle Name"
                name={"middleName"}
                rules={[
                  {
                    required: true,
                    message: "Enter middle name",
                  },
                ]}
              >
                <Input placeholder="middle name"></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name={"email"}
                rules={emailValidationRules}
              >
                <Input placeholder="email@eam.com" disabled></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <FormPhoneInput Form={Form} />
            </Col>

            <Col span={24}>
              <Form.Item
                label="Gender"
                name={"gender"}
                rules={[
                  {
                    required: true,
                    message: "Enter gender",
                  },
                ]}
              >
                <Radio.Group
                  options={GENDER_OPTIONS}
                  value="male"
                  optionType="button"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Ownership Type"
                name={"ownershipType"}
                rules={[
                  {
                    required: true,
                    message: "Enter Ownership Type",
                  },
                ]}
              >
                <Select options={BUILDING_OWNERSHIP_OPTIONS} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Year of Entry"
                name={"yearOfEntry"}
                rules={[
                  {
                    required: true,
                    message: "Enter yearOfEntry",
                  },
                ]}
              >
                <DatePicker picker="year" className="w-100" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Next Renewal Date"
                name={"nextRenewalDate"}
                rules={[
                  {
                    required: true,
                    message: "Enter nextRenewalDate",
                  },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                className="w-100"
                size="large"
                htmlType="submit"
                disabled={isLoading}
              >
                {" "}
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default EditResident;
