import { Button, Drawer, Typography } from "antd";
import React, { useState } from "react";
import AddEstate from "../components/AddEstate";
import EstateTable from "../components/EstateTable";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

const Estates = () => {
  const [showD, setShowD] = useState(false);
  return (
    <div>
      <Drawer
        visible={showD}
        onClose={() => setShowD(false)}
        title="Add Estate"
      >
        <AddEstate handleClose={() => setShowD(false)} />
      </Drawer>
      <Typography.Title level={3}>Estates</Typography.Title>
      {/* content */}
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex justify-end">
          <PermissionRestrictor requiredPermissions={["create-estate"]}>
            <Button type="primary" onClick={() => setShowD(true)}>
              Add Estate
            </Button>
          </PermissionRestrictor>
        </div>
        <EstateTable />
      </div>
    </div>
  );
};

export default Estates;
