import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import {
  emailValidationRules,
  generalValidationRules,
} from "../../../utils/validation";
import { useGetCpaatGroups } from "../hooks/useGetCpaatGroups";
import { useAddGroupsToCpaatStaff } from "../hooks/useAddGroupsToCpaatStaff";
import { openNotification } from "../../../utils/notifcations";
import { TCpaatStaff } from "../types";
import { QUERY_KEY_FOR_CPAAT_STAFF } from "../hooks/useGetCpaatStaff";
import { useQueryClient } from "react-query";

interface IProps {
  open: boolean;
  handleClose: () => void;
  cpaatStaff: TCpaatStaff;
}

export const SaveCpaatStaffInfo: React.FC<IProps> = ({
  open,
  handleClose,
  cpaatStaff,
}) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const { data } = useGetCpaatGroups();
  const { mutate, isLoading } = useAddGroupsToCpaatStaff();
  useEffect(() => {
    form.setFieldsValue({
      email: cpaatStaff.email,
      role: cpaatStaff.cpaatAdminRole,
      groups: cpaatStaff.cpaatAdminGroups,
    });
  }, [cpaatStaff, form]);
  const handleSubmit = (data: any) => {
    mutate(
      {
        email: data.email,
        props: { cpaatAdminRole: data.role, cpaatAdminGroups: data.groups },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "Staff Info updated successfully",
            // duration: 0.4,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_CPAAT_STAFF]);

          handleClose();
          form.resetFields();
        },
      }
    );
  };
  return (
    <Modal
      style={{ top: 10 }}
      title="Update Staff Info"
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="role" label="Role" rules={generalValidationRules}>
          <Select
            options={[
              "CPAAT SUPERADMIN",
              "IMPLEMENTER",
              "SUPPORT",
              "FINANCE",
            ].map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="groups"
          label="Select Group(s)"
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            options={data?.data?.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
