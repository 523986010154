import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";
import { useFetchSingleEstateDBstats } from "../../estates/hooks/useFetchSingleEstateDBstats";
import { useFetchStaff } from "../../staff/hooks/useFetchStaff";

export const SingleEstateStaffCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  const { data, isLoading } = useFetchSingleEstateDBstats({ estateId });
  const { data: administratorStaff } = useFetchStaff(estateId, {
    role: "administrator",
  });
  const { data: userAdminStaff } = useFetchStaff(estateId, {
    role: "user-admin",
  });
  const { data: limitedStaff } = useFetchStaff(estateId, { role: "limited" });
  const { data: securityStaff } = useFetchStaff(estateId, { role: "security" });

  return (
    <PrimaryCard
      {...{
        amount: data?.staff.total,
        isLoading,
        title: "Numbers of Staff",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "New",
            value: `${pluralOrSingular({
              amount: data?.staff.new,
              singular: "staff",
              plural: "staff",
            })}`,
            bgColor: "#E7FEFF",
            textColor: "#0CCD09",
          },
          {
            name: "Administrator",
            value: `${pluralOrSingular({
              amount: administratorStaff?.total,
              singular: "staff",
              plural: "staff",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
          {
            name: "User Admin",
            value: `${pluralOrSingular({
              amount: userAdminStaff?.total,
              singular: "staff",
              plural: "staff",
            })}`,
            bgColor: "#0047FF1A",
            textColor: "#0047FF",
          },
          {
            name: "Limited",
            value: `${pluralOrSingular({
              amount: limitedStaff?.total,
              singular: "staff",
              plural: "staff",
            })}`,
            bgColor: "#E7FEFF",
            textColor: "#0CCD09",
          },
          {
            name: "Security",
            value: `${pluralOrSingular({
              amount: securityStaff?.total,
              singular: "staff",
              plural: "staff",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
