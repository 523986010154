import axios from "axios";

import { useQuery } from "react-query";

import { TSingleEstateDBStats } from "../types/estateDBStats";

export const QUERY_KEY_FOR_SINGLE_ESTATE_DB_STATS = "single-estate-db-stats";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getSingleEstateDBStats = async (props: {
  estateId: string;
}): Promise<TSingleEstateDBStats> => {
  const url = `${process.env.REACT_APP_BASE_URL}/estate/dashboard-statistics/${props.estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId: props.estateId,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData: TSingleEstateDBStats = res.data.data;
  return fetchedData;
};

export const useFetchSingleEstateDBstats = (props: { estateId: string }) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_SINGLE_ESTATE_DB_STATS, props.estateId],
    () =>
      getSingleEstateDBStats({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
