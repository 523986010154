import React from "react";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { useFetchAllEstateResidents } from "../../residents/hooks/useFetchAllEstateResidents";
import { useFetchEstatePrimaryResidents } from "../../residents/hooks/useFetchEstatePrimaryResidents";
import { useFetchEstateDependants } from "../../residents/hooks/useFetchEstateDependants";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";

export const SingleEstateResidentsCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  const { data, isLoading } = useFetchAllEstateResidents(estateId);
  const { data: primaryResidents } = useFetchEstatePrimaryResidents(estateId);
  const { data: fullAccessDependants } = useFetchEstateDependants(estateId, {
    accessLevel: "full-access",
  });
  const { data: limitedDependants } = useFetchEstateDependants(estateId, {
    accessLevel: "limited",
  });

  return (
    <PrimaryCard
      {...{
        amount: data?.totalCount,
        isLoading,
        title: "Number of Residents",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "Primary",
            value: `${pluralOrSingular({
              amount: primaryResidents?.total,
              singular: "resident",
              plural: "residents",
            })}`,
            bgColor: "#E7FEFF",
            textColor: "#0CCD09",
          },
          {
            name: "Full Access",
            value: `${pluralOrSingular({
              amount: fullAccessDependants?.total,
              singular: "dependant",
              plural: "dependants",
            })}`,
            bgColor: "#0047FF1A",
            textColor: "#0047FF",
          },
          {
            name: "Limited",
            value: `${pluralOrSingular({
              amount: limitedDependants?.total,
              singular: "dependant",
              plural: "dependants",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
