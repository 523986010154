import React, { useEffect, useState } from "react";
import { CARD_CLASS_NAME } from "../../../constants/commonClassNames";
import { SelectEstate } from "../../estates/components/SelectEstate";
import { BarChart } from "../../../components/charts/BarChart";
import { MONTH_LABELS } from "../../../constants/chartData";
import { useFetchGHUsersData } from "../../users/hooks/useFetchGHUsersData";
import { TEstate } from "../../estates/types";
import { Select } from "antd";

export const MonthlyUsersOverview = () => {
  const [selectedEstate, setSelectedEstate] = useState<TEstate>();
  const [selectedYear, setSelectedYear] = useState<string>();
  const [years, setYears] = useState<string[]>([]);

  const { data } = useFetchGHUsersData({ infoType: "total-number" });

  useEffect(() => {
    if (data) {
      const appYears = Object.keys(data?.usersPerMonth);
      setYears(appYears);
      if (appYears.length > 0) setSelectedYear(appYears[appYears.length - 1]);
    }
  }, [data]);

  console.log(
    "selected",
    selectedEstate,
    Object.keys(selectedEstate?.usersPerMonth ?? {})
  );

  return (
    <div className={`${CARD_CLASS_NAME} flex flex-col gap-4`}>
      <div className="border-b pb-2 flex justify-between">
        <h4> General Monthly Additional Users </h4>
        <div className="flex gap-3">
          <SelectEstate
            onSelect={(_, data) => {
              setSelectedEstate(data);
              const estateYears = Object.keys(data?.usersPerMonth);
              setYears(estateYears);
              setSelectedYear(undefined);
              if (estateYears.length > 0)
                setSelectedYear(estateYears[estateYears.length - 1]);
            }}
            onClear={() => {
              setSelectedEstate(undefined);
              if (data) {
                const appYears = Object.keys(data?.usersPerMonth);
                setYears(appYears);
                if (appYears.length > 0)
                  setSelectedYear(appYears[appYears.length - 1]);
              }
            }}
          />
          {years.length > 0 && (
            <Select
              value={selectedYear}
              options={years.map((item) => ({ label: item, value: item }))}
              onSelect={(val: string) => setSelectedYear(val)}
            />
          )}
        </div>
      </div>

      <div>
        <BarChart
          labels={MONTH_LABELS}
          data={MONTH_LABELS.map((item) => {
            if (selectedYear) {
              return selectedEstate
                ? selectedEstate?.usersPerMonth[selectedYear][item]
                : data?.usersPerMonth[selectedYear][item];
            }
            return 0;
          })}
        />
      </div>
    </div>
  );
};
