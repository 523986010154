import { THelpPage } from "../types";
import { usePagination } from "../../../hooks/usePagination";
import Table, { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { routes } from "../../../constants/routes";
import {
  QUERY_KEY_FOR_HELP_PAGES,
  useGetHelpPages,
} from "../hooks/useGetHelpPages";
import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeleteHelpPage } from "../hooks/useDeleteHelpPage";
import { Button, Modal, Space } from "antd";
import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

export const HelpPagesTable: React.FC<{ category?: string }> = ({
  category,
}) => {
  const queryClient = useQueryClient();

  const { pagination, onChange } = usePagination();
  const { data, isFetching } = useGetHelpPages({
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
    category,
  });

  const { mutate, isLoading } = useDeleteHelpPage();

  const handleDelete = ({ id }: { id: string }) => {
    // TO DO : add loading logic for deleting page
    Modal.confirm({
      title: `Do you want to delete help page ?`,
      icon: <ExclamationCircleOutlined />,
      content: "This will permenantly delete this page and its data!",
      width: 600,
      onOk() {
        mutate(
          {
            pageId: id,
          },
          {
            onError: (err: any) => {
              openNotification({
                state: "error",
                title: "Error Occured",
                description:
                  err?.response.data.message ??
                  err?.response.data.error.message,
              });
            },
            onSuccess: (res: any) => {
              openNotification({
                state: "success",

                title: "Success",
                description: "The page was deleted successfully!",
                // duration: 0.4,
              });

              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_FOR_HELP_PAGES],
              });
            },
          }
        );
      },
    });
  };
  const columns: ColumnsType<THelpPage> = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      width: 300,
      render: (_, item) => (
        <Link
          to={routes.singleHelpPage(item._id).path}
          className="hover:underline"
        >
          <span className="font-semibold capitalize">{item.title}</span>
        </Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      width: 300,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <span className="capitalize">{item.category}</span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <PermissionRestrictor requiredPermissions={["delete-support-page"]}>
            <Button
              title="Delete Page"
              icon={<DeleteFilled />}
              type="text"
              onClick={() => handleDelete({ id: item._id })}
            />
          </PermissionRestrictor>
        </Space>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <Table
        dataSource={data?.data ?? []}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
