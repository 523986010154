import { useEffect } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { TAuthData } from "../modules/auth/types";

export const useAuthData = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated() || localStorage.getItem("cpaat_auth")) {
      // Redirect to Dashboard
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, isAuthenticated]);
  const auth = useAuthUser();

  const authDetails = auth() as unknown as TAuthData;
  console.log(authDetails, "sdsdsd");

  const token = authDetails?.token;
  const user = authDetails?.user;

  return {
    token,
    user,
  };
};
