import React, { useEffect } from "react";
import { Typography, Form, Drawer, Skeleton, Input, Button } from "antd";
import { FormPhoneInput } from "../../../components/FormPhoneInput";
import {
  emailValidationRules,
  textInputValidationRules,
} from "../../../utils/validation";

import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";

import { useUpdateBuilding } from "../hooks/useUpdateBuilding";
import {
  QUERY_KEY_FOR_SINGLE_BUILDING,
  useFetchSingleBuilding,
} from "../hooks/useFetchSingleBuilding";
import { UnitCard } from "./UnitCard";

interface IProps {
  handleClose: Function;
  id: string;
  open: boolean;
}

const EditBuilding = ({ id, handleClose, open }: IProps) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const {
    data: entity,
    isFetching,
    isSuccess,
  } = useFetchSingleBuilding({ id });
  useEffect(() => {
    if (isSuccess) {
      form.setFieldsValue({
        buildingName: entity.name,
        buildingNumber: entity.number,
        streetAddress: entity.streetAddress,
        buildingTypeId: entity.buildingTypeId,
        firstName: entity.ownerInfo[0].firstName,
        lastName: entity.ownerInfo[0].lastName,
        phone: {
          code:
            entity.ownerInfo[0] &&
            (entity.ownerInfo[0].phoneNumber.split("-")[0].length <= 4
              ? entity.ownerInfo[0].phoneNumber.split("-")[0]
              : ""),
          number:
            entity.ownerInfo[0] &&
            (entity.ownerInfo[0].phoneNumber.split("-")[0].length <= 4
              ? entity.ownerInfo[0].phoneNumber.split("-")[1]
              : entity.ownerInfo[0].phoneNumber.split("-")[0]),
        },
        email: entity.ownerInfo[0] && entity.ownerInfo[0].email,
      });
    }
  }, [isSuccess, entity, form]);

  const { mutate, isLoading } = useUpdateBuilding();
  const handleFinish = (data: any) => {
    if (entity) {
      mutate(
        {
          estateId: entity.estateId,
          buildingId: id,
          props: {
            addressLine1: "dog",
            number: "29",
          },
        },
        {
          onError: (err: any) => {
            openNotification({
              state: "error",
              title: "Error Occured",
              description:
                err?.response.data.message ?? err?.response.data.error.message,
            });
          },
          onSuccess: () => {
            openNotification({
              state: "success",

              title: "Success",
              description: "The entity was updated successfully",
              // duration: 0.4,
            });
            form.resetFields();
            handleClose();
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_FOR_SINGLE_BUILDING],
            });
          },
        }
      );
    }
  };
  return (
    <Drawer visible={open} onClose={() => handleClose()} title="Edit Building">
      <Typography.Title level={5}>Edit Information</Typography.Title>

      <Skeleton
        active
        paragraph={{ rows: 12 }}
        avatar
        loading={isFetching && !isSuccess}
      >
        <Form
          labelCol={{ span: 24 }}
          requiredMark={false}
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item
            label="Building Name (optional)"
            name={"buildingName"}
            rules={textInputValidationRules}
          >
            <Input placeholder="building name" />
          </Form.Item>

          <Form.Item
            label="Building Number"
            name={"buildingNumber"}
            rules={textInputValidationRules}
          >
            <Input placeholder="Building Number" />
          </Form.Item>
          <Form.Item
            label="Street Address"
            name={"streetAddress"}
            rules={textInputValidationRules}
          >
            <Input.TextArea placeholder="Street Address" />
          </Form.Item>
          {/* show when creating */}
          {/* {entity?.estateId && <FormBuildingTypeInput Form={Form} estateId={entity?.estateId}/>} */}
          {entity?.units && entity?.units?.length > 0 && (
            <div className="py-4 flex flex-col gap-2 mb-2">
              <span className="text-base font-semibold">Building Units</span>
              <div className="grid grid-cols-3 gap-4">
                {entity?.units?.map((item) => (
                  <UnitCard key={item.number} data={item} />
                ))}
              </div>
            </div>
          )}

          <Form.Item
            label="Owner First Name"
            name={"firstName"}
            rules={textInputValidationRules}
          >
            <Input placeholder="first name"></Input>
          </Form.Item>

          <Form.Item
            label="Owner Last Name"
            name={"lastName"}
            rules={textInputValidationRules}
          >
            <Input placeholder="last name"></Input>
          </Form.Item>

          <Form.Item
            label="Owner Email"
            name={"email"}
            rules={emailValidationRules}
          >
            <Input placeholder="email@eam.com"></Input>
          </Form.Item>

          <FormPhoneInput Form={Form} />

          <Form.Item>
            <Button htmlType="submit" loading={isLoading} type="primary">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default EditBuilding;
