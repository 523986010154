import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

export enum EOwnershipType {
  rent = "rent",
  bought = "bought",
}
interface IUpdateResProps {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender: string;
  phoneNumber: string;
  building: string;
  unit?: string;
  yearOfEntry: string;
  nextRenewalDate: string;
  prefix: string;
  ownershipType: EOwnershipType;
  residentId: string;
  photo?: any;
}
export const updatePrimaryRes = (props: IUpdateResProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/primary-resident/${props.residentId}`;

  const data = {
    photo: props.photo,

    prefix: props.prefix,
    firstName: props.firstName,
    lastName: props.lastName,
    middleName: props.middleName,
    phoneNumber: props.phoneNumber,
    gender: props.gender,
    ownershipType: props.ownershipType,
    yearOfEntry: props.yearOfEntry,
    nextRenewalDate: props.nextRenewalDate,
  };
  let formData = new FormData();
  props.photo && formData.append("photo", props.photo?.originFileObj);
  formData.append("prefix", data.prefix);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  data.middleName && formData.append("middleName", data.middleName);
  formData.append("phoneNumber", data.phoneNumber);
  formData.append("gender", data.gender);
  formData.append("ownershipType", data.ownershipType);
  formData.append("yearOfEntry", data.yearOfEntry);
  formData.append("nextRenewalDate", data.nextRenewalDate);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.patch(url, formData, config);
  return res;
};

export const useUpdatePrimaryResident = () => {
  return useMutation(updatePrimaryRes);
};
