import React from "react";
import { useFetchSingleEstateDBstats } from "../../estates/hooks/useFetchSingleEstateDBstats";
import { PrimaryCard } from "../../../components/cards/PrimaryCard";
import { pluralOrSingular } from "../../../utils/pluralOrSingular";

export const SingleEstateVisitors4TodayCard: React.FC<{ estateId: string }> = ({
  estateId,
}) => {
  const { data, isLoading } = useFetchSingleEstateDBstats({ estateId });

  return (
    <PrimaryCard
      {...{
        amount: (data?.visitor.booked ?? 0) + (data?.visitor.checkedIn ?? 0),
        isLoading,
        title: "Visitors for today",
        imgUrl: "/assets/images/blue_book.png",
        items: [
          {
            name: "Booked",
            value: `${pluralOrSingular({
              amount: data?.visitor.booked,
              singular: "visitor",
              plural: "visitors",
            })}`,
            bgColor: "#0047FF1A",
            textColor: "#0047FF",
          },
          {
            name: "Checked in",
            value: `${pluralOrSingular({
              amount: data?.visitor.checkedIn,
              singular: "visitor",
              plural: "visitors",
            })}`,
            bgColor: "#FFEFEF",
            textColor: "#EF2121",
          },
        ],
      }}
    />
  );
};
