import { useState } from "react";
import { SelectEstate } from "../../estates/components/SelectEstate";
import { ServiceRequestsTable } from "./ServicesRequestsTable";
import { Tabs } from "antd";
import { TRequestStatus } from "../hooks/useFetchServiceRequests";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";

export const ServiceRequestsContainer = () => {
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  const [key, setKey] = useState<TRequestStatus>("pending");
  return (
    <div>
      {/* content */}
      <div className="mt-4 flex flex-col gap-4">
        <div className="flex justify-between">
          <SelectEstate
            value={selectedEstateId}
            onSelect={(id) => {
              dispatch(selectEstate(id));
            }}
            onClear={() => {
              dispatch(selectEstate(undefined));
            }}
          />
        </div>
        {selectedEstateId ? (
          <Tabs
            onChange={(val) => setKey(val as TRequestStatus)}
            items={[
              {
                key: "pending",
                label: "New",
                children: (
                  <ServiceRequestsTable
                    estateId={selectedEstateId}
                    status={key}
                  />
                ),
              },
              {
                key: "accepted",
                label: "Accepted",
                children: (
                  <ServiceRequestsTable
                    estateId={selectedEstateId}
                    status={key}
                  />
                ),
              },
              {
                key: "declined",
                label: "Declined",
                children: (
                  <ServiceRequestsTable
                    estateId={selectedEstateId}
                    status={key}
                  />
                ),
              },
            ]}
          />
        ) : (
          <div>Please Select an estate</div>
        )}
      </div>
    </div>
  );
};
