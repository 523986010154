import React from "react";
import { Row, Skeleton } from "antd";

import { useFetchBuildingTypes } from "../hooks/useFetchBuildingTypes";
import BuildingTypeCard from "./BuildingTypeCard";

export const BuildingTypeCards: React.FC<{
  estateId: string;
}> = ({ estateId }) => {
  const { data, isLoading } = useFetchBuildingTypes(estateId);

  return (
    <div>
      <Skeleton loading={isLoading}>
        <Row gutter={[20, 40]}>
          {data?.data.map((item) => (
            <BuildingTypeCard key={item._id} title={item.name} />
          ))}
        </Row>
      </Skeleton>
    </div>
  );
};
