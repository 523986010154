import React, { useState } from "react";
import EditBuilding from "./EditBuilding";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined } from "@ant-design/icons";
import { TBuilding } from "../types";
import { useFetchBuildings } from "../hooks/useFetchBuildings";
import { usePagination } from "../../../hooks/usePagination";
import { Button, Space, Table } from "antd";
interface IProps {
  estateId: string;
}
export const BuildingsTable = ({ estateId }: IProps) => {
  const [buildingId, setBuildingId] = useState<string>();
  const [edit, setEdit] = useState(false);
  const handleEdit = (id: string) => {
    setBuildingId(id);
    setEdit(true);
  };
  const { pagination, onChange } = usePagination();
  const { data, isFetching } = useFetchBuildings(estateId, {
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  const columns: ColumnsType<TBuilding> = [
    {
      title: "Building No. & Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">No. {record.number}</span>
          <span> {record.name}</span>
        </div>
      ),
    },
    {
      title: "Street Name",
      dataIndex: "street",
      key: "street",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record.streetAddress}</span>
        </div>
      ),
    },

    {
      title: "Building Type & No of units",
      dataIndex: "build type",
      key: "build type",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">{record.buildingTypeId.name}</span>
          <span> {record.units?.length}</span>
        </div>
      ),
    },
    {
      title: "Owner & Occupancy",
      dataIndex: "own_occ",
      key: "own_occ",
      width: 300,

      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            {record.ownerInfo[0].firstName}
            {record.ownerInfo[0].lastName}
          </span>
          <span> {record.isOccupied ? "Occupied" : "Unoccupied"} </span>
        </div>
      ),
    },

    // {
    //   title: "",
    //   key: "action",
    //   width: 100,
    //   render: (_, item) => (
    //     <Space align="center" className="cursor-pointer">
    //       <Button
    //         title="Edit Building"
    //         icon={<EditOutlined />}
    //         type="text"
    //         onClick={() => handleEdit(item._id)}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div>
      {buildingId && (
        <EditBuilding
          id={buildingId}
          handleClose={() => {
            setEdit(false);
            setBuildingId(undefined);
          }}
          open={edit}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
