import { Col, Typography } from "antd";

interface IProps {
  title: string;
}

const BuildingTypeCard = ({ title }: IProps) => {
  return (
    <Col span={24} lg={{ span: 6 }}>
      <div className="bg-[#fff] shadow-md px-4 py-2 h-20">
        <div className="space-between align-center">
          <div className="left">
            <Typography.Title level={3}>{title}</Typography.Title>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BuildingTypeCard;
