import axios from "axios";
import { useQuery } from "react-query";
import { TEstateBuildStats } from "../types";

export const QUERY_KEY_FOR_BUILDINGS = "buildings";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getEstateBuildingStatistics = async (
  estateId: string
): Promise<TEstateBuildStats> => {
  const url = `${process.env.REACT_APP_BASE_URL}/estate/building-statistics/${estateId}`;

  const config = {
    headers: {
      estateId: estateId,
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData: TEstateBuildStats = res.data.data;

  return fetchedData;
};

export const useFetchEstateBuildingStats = (estateId?: string) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_BUILDINGS, estateId],
    () => getEstateBuildingStatistics(estateId ?? ""),
    {
      enabled: !!estateId,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
