import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TCpaatStaff } from "../types";

export const QUERY_KEY_FOR_CPAAT_STAFF = "cpaat-staff";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getData = async (
  props: IGetListDataProps
): Promise<{ data: TCpaatStaff[] }> => {
  const { pagination, searchParams } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/cpaat-data/staffs`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const result = res.data.data;

  const data: TCpaatStaff[] = result.map(
    (item: TCpaatStaff): TCpaatStaff => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
  };

  return ans;
};

export const useGetCpaatStaff = (props: IGetListDataProps = {}) => {
  const queryData = useQuery(
    [QUERY_KEY_FOR_CPAAT_STAFF, props.pagination, props.searchParams],
    () =>
      getData({
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
