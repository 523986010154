import axios from "axios";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

export interface IAddProps {
  estateOwnerId: string;
  name: string;
  description: string;
  location: string;
  nextRenewalDate: string;
  dependantFee?: number;
  primaryResidentFee: number;
  accountNumber: string;
  bankCode: string;
  addGatePassApproval?: boolean;
  photo?: any;
}

export const addEstate = async (props: IAddProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/estate`;

  const data = props;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let formData = new FormData();
  // formData.append("photo", props.photo?.originFileObj);
  // formData.append("estate[0]", JSON.stringify(data.estate[0]));
  // formData.append("estate", data.estate);

  // delete dependant fee if not provided
  // if (!props["dependantFee"]) delete data["dependantFee"];
  data["dependantFee"] = 0; // as per Mr. Fola request
  data["addGatePassApproval"] = !!data["addGatePassApproval"]; // fix
  Object.entries(data).forEach(([index, val]) => {
    formData.append(index, val);
  });

  // const response = await axios.post(url, data, config);
  const response = await axios.post(url, formData, config);
  return response;
};
export interface IEditEstateProps {
  estateId: string;
  estateOwnerId?: string;
  name?: string;
  description?: string;
  location?: string;
  nextRenewalDate?: string;
  dependantFee?: number;
  primaryResidentFee?: number;
  accountNumber?: string;
  bankCode?: string;
  addGatePassApproval?: boolean;
  country: string;
  currency: string;
  logo?: any;
}

export const updateEstate = async (props: IEditEstateProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/estate/${props.estateId}`;

  const data = props;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let formData = new FormData();
  // formData.append("logo", props.logo);
  // formData.append("estate[0]", JSON.stringify(data.estate[0]));
  // formData.append("estate", data.estate);
  Object.entries(data).forEach(([index, val]) => {
    if (val) formData.append(index, val);
  });

  // const response = await axios.patch(url, data, config);
  const response = await axios.patch(url, formData, config);
  return response;
};

interface IGetProps {
  limit?: string;
  offset?: string;
  status?: string;
  isDeleted?: string;
}
export const getEstates = async ({
  limit = "",
  offset = "",
  status = "active",
  isDeleted = "false",
}: IGetProps) => {
  const ilimit = limit ?? 10;
  const ioffset = offset ?? 0;
  const url = `${process.env.REACT_APP_BASE_URL}/estate?limit=${ilimit}&offset=${ioffset}&status=${status}&isDeleted=${isDeleted}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(url, config);
  return response;
};

interface ISingleGetProps {
  estateId: string;
}
export const getEstate = async ({ estateId }: ISingleGetProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/estate/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(url, config);
  return response;
};
