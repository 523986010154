import React from "react";
import { CARD_CLASS_NAME } from "../../constants/commonClassNames";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePagination } from "../../hooks/usePagination";
interface IProps<DataType extends object = any> {
  title: string;
  isSearchable?: boolean;
  data?: DataType[];
  isLoading?: boolean;
  // columns: { title: string; key: keyof DataType }[];
  columns: ColumnsType<DataType>;
}
export const DataActionableCard = ({
  title,
  isSearchable = false,
  data,
  isLoading,
  columns,
}: IProps) => {
  const { pagination, onChange } = usePagination({ pageSize: 2 });
  return (
    <div className={`${CARD_CLASS_NAME} flex flex-col gap-4`}>
      <div className="border-b pb-2 flex justify-between">
        <h4 className={`${!isSearchable ? "text-center flex-1" : "text-left"}`}>
          {title}
        </h4>
        {isSearchable && (
          <div className="w-1/4">
            <Input.Search placeholder="Search" size="small" />
          </div>
        )}
      </div>

      <div>
        <Table
          columns={columns.map((item) => ({
            dataIndex: item.key as unknown as string,
            title: item.title,
            key: item.key as unknown as string,
            ellipsis: true,
          }))}
          loading={isLoading}
          dataSource={data?.map((item, i) => ({ ...item, key: i }))}
          size="small"
          onChange={onChange}
          pagination={{ ...pagination, total: data?.length }}
        />
      </div>
    </div>
  );
};
