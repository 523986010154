import React from "react";
import { TBuildingUnit } from "../types";

export const UnitCard: React.FC<{ data: TBuildingUnit }> = ({ data }) => {
  return (
    <div className=" bg-[#f8f8f8] shadow-md px-4 py-2">
      <h4 className="text-sm">
        {data.number}, {data.floor}
      </h4>
      <span className="text-xs">No: {data.number}</span>
    </div>
  );
};
