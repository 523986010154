import axios from "axios";
import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TDependant, TDependantAccessLevel } from "../types/dependant";

export const QUERY_KEY_FOR_ESTATE_DEPENDANTS = "dependants";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getEstateDependants = async (
  estateId: string,
  props: IGetListDataProps & { accessLevel?: TDependantAccessLevel }
): Promise<{ data: TDependant[]; total: number }> => {
  const { pagination, searchParams, accessLevel } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/dependant/estate/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId,
    },
    params: {
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
      accessLevel,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: TDependant[] = result.map(
    (item: TDependant): TDependant => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchEstateDependants = (
  estateId: string,
  props: IGetListDataProps & { accessLevel?: TDependantAccessLevel } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_ESTATE_DEPENDANTS,
      props.pagination,
      props.searchParams,
      estateId,
      props.accessLevel,
    ],
    () =>
      getEstateDependants(estateId, {
        ...props,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
