import { Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CpaatGroupContainer } from "../components/CpaatGroupContainer";
import { CpaatStaffContainer } from "../components/CpaatStaffContainer";
import { CpaatRolesPermissionsContainer } from "../components/CpaatRolesPermissionsContainer";
import { useAuthData } from "../../../hooks/useAuthData";
import { canUserAccessComponent } from "../../../components/permission-restrictor/PermissionRestrictor";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { openNotification } from "../../../utils/notifcations";

const Administration = () => {
  const [key, setKey] = useState<string>("Cpaat Groups");
  const handleKey = (val: string) => {
    setKey(val);
  };
  const { user } = useAuthData();
  let tabItems = [
    {
      key: "Cpaat Groups",
      label: "Cpaat Groups",
      children: <CpaatGroupContainer />,
    },
    {
      key: "Cpaat Staff",
      label: "Cpaat Staff",
      children: <CpaatStaffContainer />,
    },
    {
      key: "Roles & Permissions",
      label: "Roles & Permissions",
      children: <CpaatRolesPermissionsContainer />,
    },
  ];
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     canUserAccessComponent({
  //       userRoleName: user.cpaatAdminRole,
  //       requiredPermissions: ["admin-features"],
  //     }) === false
  //   ) {
  //     openNotification({
  //       state: "error",

  //       title: "Unauthorized Access",
  //       description: "You don't have permission to view Administration page!",
  //       // duration: 0.4,
  //     });
  //     navigate(routes.home);
  //   }
  // }, [user, navigate]);

  return (
    <div>
      <Typography.Title level={3}>Administration</Typography.Title>

      <Tabs onChange={(val) => setKey(val)} activeKey={key} items={tabItems} />
    </div>
  );
};

export default Administration;
