import axios from "axios";

import { useQuery } from "react-query";
import { IGetListDataProps } from "../../../types";
import { TBuilding } from "../types";

export const QUERY_KEY_FOR_BUILDINGS = "buildings";
const token = localStorage.getItem("cpaat_auth") as unknown as string;

const getBuildings = async (
  estateId: string,
  props: IGetListDataProps & { isOccupied?: boolean }
): Promise<{ data: TBuilding[]; total: number }> => {
  const { pagination, searchParams, isOccupied } = props;
  const url = `${process.env.REACT_APP_BASE_URL}/building/estate/${estateId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      isOccupied,
      limit: pagination?.limit ?? 4,
      offset: pagination?.offset ?? 0,
      search: searchParams?.name,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;
  const result = fetchedData.result;

  const data: TBuilding[] = result.map(
    (item: TBuilding): TBuilding => ({
      ...item, //adheres to backend
    })
  );

  const ans = {
    data,
    total: fetchedData.totalCount,
  };

  return ans;
};

export const useFetchBuildings = (
  estateId?: string,
  props: IGetListDataProps & { isOccupied?: boolean } = {}
) => {
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_BUILDINGS,
      props.pagination,
      props.searchParams,
      props.isOccupied,
      estateId,
    ],
    () =>
      getBuildings(estateId ?? "", {
        ...props,
      }),
    {
      enabled: !!estateId,
      onError: (err: any) => {},
      onSuccess: (data) => {},
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // ...preventUnnecessaryRefresh
    }
  );

  return queryData;
};
