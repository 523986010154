import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Button,
  Input,
  Form,
  Radio,
  message,
  Upload,
  Avatar,
  Drawer,
  Skeleton,
} from "antd";

import { RcFile } from "antd/es/upload/interface";

import { FormPhoneInput } from "../../../components/FormPhoneInput";
import {
  textInputValidationRules,
  textInputValidationRulesOp,
  emailValidationRules,
  generalValidationRules,
} from "../../../utils/validation";
import { useFetchSingleStaff } from "../hooks/useFetchSingleStaff";
import { useUpdateStaff } from "../hooks/useUpdateStaff";
import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_STAFF } from "../hooks/useFetchStaff";

interface IProps {
  handleClose: Function;
  id: string;
  open: boolean;
}

const EditStaff = ({ id, handleClose, open }: IProps) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const [fileList, setFilelist] = useState<any>([]);

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const [selectedRole, setSelectedRole] = useState("");
  const handleRoleSelect = (val: any) => {
    setSelectedRole(val);
  };

  const { data: staff, isFetching, isSuccess } = useFetchSingleStaff({ id });
  useEffect(() => {
    if (isSuccess) {
      const data = staff.estate[0];
      setSelectedRole(data.role);
      form.setFieldsValue({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: {
          code:
            staff &&
            (data.phoneNumber.split("-")[0].length <= 4
              ? data.phoneNumber.split("-")[0]
              : ""),
          number:
            staff &&
            (data.phoneNumber.split("-")[0].length <= 4
              ? data.phoneNumber.split("-")[1]
              : data.phoneNumber.split("-")[0]),
        },
        homeAddress: data.homeAddress,
        gender: data.gender,
        middleName: data?.middleName === "undefined" ? "" : data?.middleName,
        phoneNumber: data.phoneNumber,
        accessLevel: data?.accessLevel,
        role: data?.role,
        suffix: data?.suffix,
      });
    }
  }, [isSuccess, staff, form]);

  const { mutate, isLoading } = useUpdateStaff();
  const handleFinish = (data: any) => {
    const prefix = data.gender === "male" ? "Mr." : "Mrs.";

    mutate(
      {
        ...data,
        prefix,
        staffId: id,
        phoneNumber: `${data.phone.code}-${data.phone.number}`,

        photo: fileList[0],
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The staff was updated successfully",
            // duration: 0.4,
          });
          form.resetFields();
          setFilelist([]);
          handleClose();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_STAFF],
          });
        },
      }
    );
  };
  return (
    <Drawer visible={open} onClose={() => handleClose()} title="Edit Staff">
      <Typography.Title level={5}>Edit Information</Typography.Title>

      <Skeleton
        active
        paragraph={{ rows: 12 }}
        avatar
        loading={isFetching && !isSuccess}
      >
        <Form
          labelCol={{ span: 24 }}
          requiredMark={false}
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={[30, 5]}>
            <Col span={24} className="">
              <Typography.Text>
                {fileList.length !== 1
                  ? "Profile Photo:"
                  : "Profile image has been inserted"}
              </Typography.Text>
              <br />
              <div
                className={`d-flex align-center w-full ${
                  fileList.length === 0 ? "space-between" : "justify-stretch"
                }`}
              >
                <Upload
                  listType="picture"
                  fileList={fileList}
                  className="avatar-uploader w-full"
                  onChange={handleUpload}
                  onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFilelist(newFileList);
                  }}
                  beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
                >
                  {fileList.length !== 1 && (
                    <Button type="dashed">Upload profile image</Button>
                  )}
                </Upload>
                {fileList.length === 0 && (
                  <Avatar
                    src={staff?.estate[0]?.photo}
                    size={72}
                    shape="square"
                  />
                )}
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First name"
                name={"firstName"}
                rules={textInputValidationRules}
              >
                <Input placeholder="first name"></Input>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Last name"
                name={"lastName"}
                rules={textInputValidationRules}
              >
                <Input placeholder="last name"></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Middle name"
                name={"middleName"}
                rules={textInputValidationRulesOp}
              >
                <Input placeholder="middle name"></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name={"email"}
                rules={emailValidationRules}
              >
                <Input placeholder="email@eam.com" disabled></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <FormPhoneInput Form={Form} />
            </Col>
            <Col span={24}>
              <Form.Item
                label="Gender"
                name={"gender"}
                rules={generalValidationRules}
              >
                <Radio.Group
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  value="male"
                  optionType="button"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Role"
                name={"role"}
                rules={generalValidationRules}
              >
                <Select onSelect={handleRoleSelect}>
                  {["limited", "user-admin", "administrator", "security"].map(
                    (role) => (
                      <Select.Option key={role} value={role}>
                        {`${role}`}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            {selectedRole === "security" && (
              <Col span={24}>
                <Form.Item
                  label="Access level"
                  name={"accessLevel"}
                  rules={generalValidationRules}
                >
                  <Select>
                    {["limited", "full-access"].map((role) => (
                      <Select.Option key={role} value={role}>
                        {`${role}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {/* not needed */}

            {/* <Col span={24}>
              <Form.Item
                label="Suffix"
                name={"suffix"}
                rules={[
                  {
                    required: true,
                    message: "Enter suffix",
                  },
                ]}
              >
                <Select defaultValue={staff?.suffix}>
                  {["jnr", "snr"].map((role) => (
                    <Select.Option key={role} value={role}>
                      {`${role}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label="House Address"
                name={"homeAddress"}
                rules={textInputValidationRules}
              >
                <Input.TextArea placeholder="House Number and Street"></Input.TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                className="w-100"
                size="large"
                htmlType="submit"
                loading={isLoading}
              >
                {" "}
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default EditStaff;
