import axios from "axios";
import { useMutation } from "react-query";

const token = localStorage.getItem("cpaat_auth") as unknown as string;

// TO DO : Ensure this is the correct schema to be used
interface IUpdateDataProps {
  title?: string;
  content?: string;
  category?: string;
}
export const createData = (vals: { props: IUpdateDataProps; id: string }) => {
  const { props, id } = vals;
  const url = `${process.env.REACT_APP_BASE_URL}/help-page/content/${id}`;

  const data = { ...props };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res: any = axios.patch(url, data, config);
  return res;
};

export const useUpdateHelpPage = () => {
  return useMutation(createData);
};
