import { Button, Modal, Space, Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { usePagination } from "../../../hooks/usePagination";
import { useEffect, useState } from "react";
import { openNotification } from "../../../utils/notifcations";
import { useQueryClient } from "react-query";
import { useDeletePrimaryResident } from "../hooks/useDeletePrimaryResident";
import {
  QUERY_KEY_FOR_ESTATE_PRIMARY_RESIDENTS,
  useFetchEstatePrimaryResidents,
} from "../hooks/useFetchEstatePrimaryResidents";
import { TPrimaryResident } from "../types/primaryResident";
import EditResident from "./EditResident";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";

interface IProps {
  estateId: string;
}

export const ResidentsTable = ({ estateId }: IProps) => {
  const queryClient = useQueryClient();

  const [residentId, setResidentId] = useState<string>();
  const [edit, setEdit] = useState(false);
  const { pagination, onChange, resetPagination } = usePagination();
  useEffect(() => {
    //so as to enable pagination to be reset evry time estate id changes
    resetPagination();
  }, [estateId, resetPagination]);

  const { data, isFetching } = useFetchEstatePrimaryResidents(estateId, {
    pagination: {
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  const handleEdit = (id: string) => {
    setResidentId(id);
    setEdit(true);
  };
  const { mutate, isLoading } = useDeletePrimaryResident();

  const handleDelete = ({ id }: { id: string }) => {
    setResidentId(id);

    Modal.confirm({
      title: `Do you want to delete resident ?`,
      icon: <ExclamationCircleOutlined />,
      content: "This will permenantly delete this resident!",
      width: 600,
      onOk() {
        mutate(
          {
            residentId: id,
            estateId,
          },
          {
            onError: (err: any) => {
              openNotification({
                state: "error",
                title: "Error Occured",
                description:
                  err?.response.data.message ??
                  err?.response.data.error.message,
              });
            },
            onSuccess: (res: any) => {
              openNotification({
                state: "success",

                title: "Success",
                description: "The resident was deleted successfully!",
                // duration: 0.4,
              });
              setResidentId(undefined);

              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_FOR_ESTATE_PRIMARY_RESIDENTS],
              });
            },
          }
        );
      },
    });
  };

  const columns: ColumnsType<TPrimaryResident> = [
    {
      title: "Name & Email",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            {record.estate[0]?.firstName} {record.estate[0]?.lastName}
          </span>
          <span> {record.estate[0]?.email}</span>
        </div>
      ),
    },
    {
      title: "Resident Type & Entry year",
      dataIndex: "res_type",
      key: "res_type",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">Primary Resident</span>
          <span> {record.estate[0]?.yearOfEntry}</span>
        </div>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 300,

      render: (value, record) => (
        <span className="font-bold">{record.estate[0]?.phoneNumber}</span>
      ),
    },
    {
      title: "Building  & Ownership",
      dataIndex: "build_",
      key: "build_",
      width: 300,
      render: (value, record) => (
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            {record.estate[0]?.building[0]?.buildingId.number},{" "}
            {record.estate[0]?.building[0]?.buildingId.name},{" "}
            {record.estate[0]?.building[0]?.buildingId.streetAddress}
          </span>
          <span> {record.estate[0]?.building[0]?.ownershipType}</span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_, item) => (
        <Space align="center" className="cursor-pointer">
          <PermissionRestrictor requiredPermissions={["edit-resident"]}>
            <Button
              title="Edit Resident"
              icon={<EditOutlined />}
              type="text"
              onClick={() => handleEdit(item.estate[0]?._id)}
            />
          </PermissionRestrictor>
          <PermissionRestrictor requiredPermissions={["delete-resident"]}>
            <Button
              title="Delete Resident"
              icon={<DeleteFilled />}
              loading={residentId === item.estate[0]?._id && isLoading}
              type="text"
              onClick={() => handleDelete({ id: item.estate[0]?._id })}
            />
          </PermissionRestrictor>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {residentId && (
        <EditResident
          id={residentId}
          handleClose={() => {
            setEdit(false);
            setResidentId(undefined);
          }}
          open={edit}
        />
      )}
      <Table
        dataSource={data?.data}
        loading={isFetching}
        columns={columns}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
