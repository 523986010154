import { Tabs } from "antd";

import { ResidentsContainer } from "../components/ResidentsContainer";

export const Residents = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            key: "residents",
            label: "Residents",
            children: <ResidentsContainer />,
          },
          // {
          //   key: "dependants",
          //   label: "Dependants",
          //   children: <div />,
          // },
        ]}
      />
    </div>
  );
};
